import { toast } from 'react-toastify';

const Toast = (
  message: string,
  toastPosition?: 'bottom-center' | 'bottom-left' | 'bottom-right' | 'top-center' | 'top-left' | 'top-right',
  toastType?: 'dark' | 'default' | 'info' | 'success' | 'warning' | 'error',
  autoClose?: number | false,
  style?: React.CSSProperties,
  closeOnClick?: boolean,
  draggable?: boolean
) => {
  const pos = () => {
    switch (toastPosition) {
      case 'bottom-center':
        return toast.POSITION.BOTTOM_CENTER;
      case 'bottom-left':
        return toast.POSITION.BOTTOM_LEFT;
      case 'top-center':
        return toast.POSITION.TOP_CENTER;
      case 'top-left':
        return toast.POSITION.TOP_LEFT;
      case 'top-right':
        return toast.POSITION.TOP_RIGHT;
      case 'bottom-right':
      default:
        return toast.POSITION.BOTTOM_RIGHT;
    }
  };

  const err = () => {
    switch (toastType) {
      case 'default':
        return toast.TYPE.DEFAULT;
      case 'info':
        return toast.TYPE.INFO;
      case 'success':
        return toast.TYPE.SUCCESS;
      case 'warning':
        return toast.TYPE.WARNING;
      default:
        return toast.TYPE.ERROR;
    }
  };

  return toast(message, {
    position: pos(),
    type: err(),
    autoClose,
    hideProgressBar: false,
    closeOnClick: closeOnClick,
    pauseOnHover: true,
    draggable: draggable,
    progress: undefined,
    style: style,
  });
};

export default Toast;
