import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import UserRoutes from '../routes/user.routes';
import AdminRoutes from '../routes/admin.routes';
import theme from '../components/Theme';
import { AuthProvider } from '../hooks/auth';
import { ResizeProvider } from '../hooks/resize';
import { ToastContainer } from '../components';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <ResizeProvider>
            <UserRoutes />
            <AdminRoutes />
            <ToastContainer />
          </ResizeProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;