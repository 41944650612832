import styled from 'styled-components';

interface IImageContainer {
  size?: number;
  color?: string;
  borderColor?: string;
}

const ImageContainer = styled.div`
  img {
    width: ${(props: IImageContainer) => `${props.size}px`};
    border: ${({ borderColor }) => `1px solid ${borderColor}`};
  }
`;

export default ImageContainer;
