import React from 'react';
import RowContainer from './styles';

interface IRow {
  fluid?: boolean;

  centered?: boolean;
  spacedBetween?: boolean;
  spacedAround?: boolean;
  justifyContent?:
    | 'start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';

  alignItems?: 'center' | 'start' | 'end';
  verticallyCentered?: boolean;
  horizontallyCentered?: boolean;

  padded?: boolean;
  veryPadded?: boolean;
  horizontallyPadded?: boolean;
  verticallyPadded?: boolean;
  
  height?: number | string;
  width?: number | string;
}

const Row: React.FC<IRow> = ({
  fluid,

  justifyContent,
  centered,
  spacedBetween,
  spacedAround,

  alignItems,
  verticallyCentered,
  horizontallyCentered,

  padded,
  veryPadded,
  horizontallyPadded,
  verticallyPadded,
  width,
  height,

  children,
}) => {
  return (
    <RowContainer
      fluid={fluid}
      justifyContent={justifyContent}
      centered={centered}
      spacedAround={spacedAround}
      spacedBetween={spacedBetween}
      alignItems={alignItems}
      verticallyCentered={verticallyCentered}
      horizontallyCentered={horizontallyCentered}
      padded={padded}
      veryPadded={veryPadded}
      verticallyPadded={verticallyPadded}
      horizontallyPadded={horizontallyPadded}
      width={width}
      height={height}
    >
      {children}
    </RowContainer>
  );
};

export default Row;
