import React, { createContext, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { userApi, admApi } from '../services/api';
import { signIn as signUserIn } from '../pages/Auth/requests';
import { signAdmIn } from '../pages/Admin/Auth/requests';
import { IAuthContext, IUser } from './interfaces';

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [token, setToken] = useState('');
  const [user, setUser] = useState<IUser | null>(null);
  const [signedIn, setSignedIn] = useState(false);

  const [admToken, setAdmToken] = useState('');
  const [adm, setAdm] = useState<IUser | null>(null);
  const [admSignedIn, setAdmSignedIn] = useState(false);

  useEffect(() => {
    const loadStoredData = async () => {
      const userToken = localStorage.getItem('@HELPS:token');
      const userData = localStorage.getItem('@HELPS:usr');

      const admToken = localStorage.getItem('@HELPS:admToken');
      const admData = localStorage.getItem('@HELPS:adm');

      if (userToken && userData) {
        userApi.defaults.headers.Authorization = `Bearer ${userToken}`;
        setToken(userToken);
        setUser(JSON.parse(userData));
        setSignedIn(true);
      } else {
        clearUserSession();
      }

      if (admToken && admData) {
        admApi.defaults.headers.Authorization = `Bearer ${admToken}`;
        setAdmToken(admToken);
        setAdm(JSON.parse(admData));
        setAdmSignedIn(true);
      } else {
        clearAdmSession();
      }
    };
    
    loadStoredData();
  }, []);
  
  const clearUserSession = () => {
    localStorage.removeItem('@HELPS:token');
    localStorage.removeItem('@HELPS:usr');
    delete userApi.defaults.headers.common['Authorization'];

    setToken('');
    setUser(null);
    setSignedIn(false);
  };

  const clearAdmSession = () => {
    localStorage.removeItem('@HELPS:admToken');
    localStorage.removeItem('@HELPS:adm');
    delete admApi.defaults.headers.common['Authorization'];

    setAdmToken('');
    setAdm(null);
    setAdmSignedIn(false);
  };

  const signIn = async (values: any) => {
    const response = await signUserIn(values);

    if (response) {
      setUser(response.userData);
      setToken(response.token);
      setSignedIn(true);

      return true;
    }

    return false;
  };

  const admSignIn = async (values: any) => {
    const response = await signAdmIn(values);

    if (response) {
      setAdm(response.admData);
      setAdmToken(response.admToken);
      setAdmSignedIn(true);

      return true;
    }

    return false;
  };

  const signUserOut = async () => {
    clearUserSession();
    history.push('/');
  };

  const signAdmOut = async () => {
    clearAdmSession();
    history.push('/admin');
  };

  return (
    <AuthContext.Provider value={{ token, user, signedIn, admToken, adm, admSignedIn, signIn, signUserOut, admSignIn, signAdmOut }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used from within an AuthProvider');
  }

  return context;
}