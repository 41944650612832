import React from 'react';
import { Route } from 'react-router-dom';
import MyGradesPage from '../pages/MyGrades';
import QuestionsPage from '../pages/Questions';
import MinimunScorePage from '../pages/PassingScore';
import PersonalDataPage from '../pages/Profile/PersonalData';
import LoginDataPage from '../pages/Profile/LoginData';

import PrivacyPolicyPage from '../pages/PrivacyPolicy';

import PublicRoutes from '../routes/userpublic.routes';

const PrivateRoute: React.FC = () => {
  return (
    <>
      <Route path="/minhas-notas" exact component={MyGradesPage} />
      <Route path="/questionario" exact component={QuestionsPage} />
      {/* <Route path="/nota-de-corte" exact component={MinimunScorePage} /> */}
      <Route path="/dados-pessoais" exact component={PersonalDataPage} />
      <Route path="/dados-pessoais/login" exact component={LoginDataPage} />
      <Route path="/politica-de-privacidade" exact component={PrivacyPolicyPage} />
      

      <PublicRoutes />
    </>
  )
}

export default PrivateRoute;
