import React from 'react';
import PublicRoute from './userpublic.routes';
import PrivateRoute from './userprivate.routes';
import { useAuth } from '../hooks/auth';

const Routes: React.FC = () => {
  const { signedIn } = useAuth();

  if (signedIn) return <PrivateRoute />;

  return <PublicRoute />;
}

export default Routes;