import React from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { RiTableLine } from 'react-icons/ri';
import { BiLineChart } from 'react-icons/bi';
import { FiLogOut, FiUsers } from 'react-icons/fi';
import {RiQuestionAnswerLine} from 'react-icons/ri';
import { useHistory } from 'react-router';
import Container from '../../Atoms/Container';
import Theme from '../../Theme';
import { useAuth } from '../../../hooks/auth';
import { useStyles } from './styles';

interface ISideMenu {
  title: string;
}

const SideMenu: React.FC<ISideMenu> = ({ children, title }) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { signAdmOut } = useAuth();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>

        <Divider />

        <List>
          <ListItem button onClick={() => history.push('/admin/dashboard')}>
            <ListItemIcon><BiLineChart size={22} /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>

          <ListItem button onClick={() => history.push('/admin/nota-de-corte')}>
            <ListItemIcon><RiTableLine size={22} /></ListItemIcon>
            <ListItemText primary="Notas de corte" />
          </ListItem>
          
          <ListItem button onClick={() => history.push('/admin/perguntas-e-respostas')}>
            <ListItemIcon><RiQuestionAnswerLine size={22} /></ListItemIcon>
            <ListItemText primary="Perguntas e respostas" />
          </ListItem>
          
          <ListItem button onClick={() => history.push('/admin/usuarios')}>
            <ListItemIcon><FiUsers size={22} /></ListItemIcon>
            <ListItemText primary="Usuários" />
          </ListItem>
        </List>

        <Divider />

        <List>
          <ListItem button onClick={() => signAdmOut()}>
            <ListItemIcon><FiLogOut size={22} color={Theme.palette.error.main} /></ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </List>
      </Drawer>

      <Container style={{
        flexGrow: 1,
        padding: Theme.spacing(3),
      }}>
        <Container style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: Theme.spacing(0, 1),
          // necessary for content to be below app bar
          ...Theme.mixins.toolbar,
        }} />
        {children}
      </Container>
    </div>
  );
};

export default SideMenu;