import React from "react";
import { FcIdea } from 'react-icons/fc';
import Container from '../../Atoms/Container';
import Row from '../../Atoms/Row';
import Spacing from '../../Atoms/Spacing';
import Text from '../../Atoms/Text';

const AskForHelps: React.FC = () => {
  return (
    <Spacing vertical={30}>
      <Container fluid centered>
        <Text center bold>Precisa de ajuda com o seu currículo para Residência Médica?</Text>

        <Row verticallyCentered>
          <Spacing right={10}>
            <FcIdea size={20} />
          </Spacing>
          <a href="https://www.instagram.com/helpsmed/" target="_blank">
            <Text bold>Pede um HELPS!</Text>
          </a>
        </Row>
      </Container>
    </Spacing>
  )
}

export default AskForHelps;