import React, { useState, useEffect } from 'react';
import { Button, IconButton, InputAdornment, TextField, OutlinedInput, FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { Container, HomeSideDiv, Row, Text, Title, Image, Spacing } from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { useResize } from '../../../hooks/resize';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useHistory, Link } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import calcLogo from '../../../assets/calculadora_PSU-4.png';

const LoginPage: React.FC = () => {
  const history = useHistory();
  const { signIn, signedIn } = useAuth();
  const { mobile } = useResize();

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (signedIn) {
      history.push('/minhas-notas');
    }
  }, [history, signedIn]);

  const handleLogin = async (values: any) => {
    const { email, password } = values || {};
    const response = await signIn({ email: email.toLowerCase(), password });

    if (response) {
      history.push('/minhas-notas');
    }
  }

  return (
    <>
      {!mobile ? (
        <Row>
          <HomeSideDiv />

          <Container width="75%" fluid centered>
            <Container veryPadded width={400}>
              <Title size="regular">Calculadora PSU HELPS</Title>

              <Spacing bottom={30} />

              <Formik
                initialValues={{
                  email: '',
                  password: ''
                }}
                validationSchema={
                  yup.object().shape({
                    email: yup.string().email('Informe um e-mail válido').required('Informe seu e-mail'),
                    password: yup.string().required('Informe uma senha'),
                  })
                }
                enableReinitialize
                onSubmit={(values) => handleLogin(values)}
              >
                {({ values, handleSubmit, errors, touched, setFieldTouched, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <TextField
                      required
                      variant="outlined"
                      label="E-mail"
                      value={values.email}
                      style={{ width: '100%' }}
                      onBlur={() => setFieldTouched('email')}
                      onChange={(value) => setFieldValue('email', value.target.value)}
                      error={!!errors.email && !!touched.email}
                      helperText={errors.email && touched.email ? errors.email : undefined}
                    />

                    <Spacing bottom={30} />

                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <InputLabel htmlFor="password">Senha</InputLabel>

                      <OutlinedInput
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>}
                        labelWidth={70}
                        onBlur={() => setFieldTouched('password')}
                        onChange={(value) => setFieldValue('password', value.target.value)}
                        error={!!errors.password && !!touched.password}
                      />
                      {errors.password && touched.password && (
                        <FormHelperText error id="accountId-error">
                          {errors.password}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <Container style={{ justifyContent: 'flex-end' }}>
                      <Link to='/esqueci-minha-senha'>
                        <Text right>Esqueci minha senha</Text>
                      </Link>
                    </Container>

                    <Spacing top={5} bottom={15}>
                      <Button variant="contained" color="secondary" type="submit" style={{ textTransform: 'capitalize' }}>
                        Entrar
                      </Button>
                    </Spacing>
                  </Form>
                )}
              </Formik>

              <Spacing bottom={30} />

              <Text center>Ainda não possui conta? {' '}
                <Link to="/cadastrar">
                  Cadastre-se agora
                </Link>
              </Text>
            </Container>
          </Container>
        </Row>
      ) : (
        <Container veryPadded>
          <Container fluid centered>
            <Image source={calcLogo} size={250} />
          </Container>

          <Spacing bottom={30} />

          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={
              yup.object().shape({
                email: yup.string().email('Informe um e-mail válido').required('Informe seu e-mail'),
                password: yup.string().required('Informe uma senha'),
              })
            }
            enableReinitialize
            onSubmit={(values) => handleLogin(values)}
          >
            {({ values, handleSubmit, errors, touched, setFieldTouched, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  required
                  variant="outlined"
                  label="E-mail"
                  value={values.email}
                  style={{ width: '100%' }}
                  onBlur={() => setFieldTouched('email')}
                  onChange={(value) => setFieldValue('email', value.target.value)}
                  error={!!errors.email && !!touched.email}
                  helperText={errors.email && touched.email ? errors.email : undefined}
                />

                <Spacing bottom={30} />

                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel htmlFor="password">Senha</InputLabel>

                  <OutlinedInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>}
                    labelWidth={70}
                    onBlur={() => setFieldTouched('password')}
                    onChange={(value) => setFieldValue('password', value.target.value)}
                    error={!!errors.password && !!touched.password}
                  />
                  {errors.password && touched.password && (
                    <FormHelperText error id="accountId-error">
                      {errors.password}
                    </FormHelperText>
                  )}
                </FormControl>

                <Spacing top={5} bottom={15}>
                  <Container style={{ justifyContent: 'flex-end' }}>
                    <Link to='/esqueci-minha-senha'>
                      <Text right>Esqueci minha senha</Text>
                    </Link>
                  </Container>
                </Spacing>

                <Button variant="contained" color="secondary" type="submit" style={{ textTransform: 'capitalize' }}>
                  Entrar
                </Button>
              </Form>
            )}
          </Formik>

          <Spacing bottom={30} />

          <Text center>Ainda não possui conta? {' '}
            <Link to="/cadastrar">
              Cadastre-se agora
            </Link>
          </Text>
        </Container>
      )}
    </>

  )
}

export default LoginPage;