import { ToastContainer as Toast } from 'react-toastify';
import styled from 'styled-components';
import theme from '../../Theme';

const ToastContainer = styled(Toast).attrs({})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
  }
  .Toastify__toast--error {
    background: ${theme.palette.white.main};
    border: 1px solid ${theme.palette.error.main};
    border-radius: 5px;
    border-left: 8px solid ${theme.palette.error.main};
    color: ${theme.palette.error.main};
  }
  .Toastify__toast--warning {
    background: ${theme.palette.white.main};
    border: 1px solid ${theme.palette.warning.main};
    border-radius: 5px;
    border-left: 8px solid ${theme.palette.warning.main};
    color: ${theme.palette.warning.main};
  }
  .Toastify__toast--success {
    background: ${theme.palette.white.main};
    border: 1px solid ${theme.palette.success.main};
    border-radius: 5px;
    border-left: 8px solid ${theme.palette.success.main};
    color: ${theme.palette.success.main};
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

export default ToastContainer;
