import React from 'react';
import TitleContainer from './styles';

interface ITitle {
  size?: 'big' | 'regular' | 'small' | 'tiny';
  color?: string;
  bold?: boolean;
  center?: boolean;
}

const Title: React.FC<ITitle> = ({ children, size = 'big', center, color, bold = true }) => {
  const titleSize = () => {
    switch (size) {
      case 'regular':
        return <h2>{children}</h2>;
      case 'small':
        return <h3>{children}</h3>;
      case 'tiny':
        return <h4>{children}</h4>;
      default:
        return <h1>{children}</h1>;
    }
  };

  return (
    <TitleContainer center={center} color={color} isBold={bold}>
      {titleSize()}
    </TitleContainer>
  );
};

export default Title;
