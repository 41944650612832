import React from 'react';
import Container from '../../Atoms/Container';
import Row from '../../Atoms/Row';
import Image from '../../Atoms/Image';
import Spacing from '../../Atoms/Spacing';
import Text from '../../Atoms/Text';
import Theme from '../../Theme';
import instagram from '../../../assets/instagram.svg'

const SocialMedia: React.FC = () => {
  return (
    <Container style={{ position: 'absolute', bottom: 10, margin: '0 auto' }}>
      <Row verticallyCentered>
        <a href="https://www.instagram.com/helps_med/" target="_blank">
          <Image source={instagram} size={25} />
        </a>

        <Spacing left={20}>
          <a href="https://www.instagram.com/helpsmed/" target="_blank">
            <Text bold color={Theme.palette.secondary.main}>Fale conosco!</Text>
          </a>
        </Spacing>

      </Row>
    </Container>
  )
}

export default SocialMedia;