import React, { useEffect, useState } from 'react';
import InputMask from "react-input-mask";
import { Button, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { Container, Row, Text, Menu, Page, Spacing, Toast } from '../../../components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { getUserInfo, updateUserInfo } from '../requests';
import { useAuth } from '../../../hooks/auth';
import { useResize } from '../../../hooks/resize';

interface IUserInfo {
  name: string;
  college: string;
  phone: string;
  period: number;
  allowCommunication: boolean;
};

interface IPersonalDataForm {
  userInfo: IUserInfo | undefined;
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  update: (values: any) => void;
}

const PersonalDataForm: React.FC<IPersonalDataForm> = ({ userInfo, update, checked, setChecked }) => {
  const getValidationSchema = () =>
    yup.object().shape({
      name: yup.string().required('Informe seu nome'),
      phone: yup.string().required('Informe seu telefone'),
      college: yup.string().required('Informe o nome da faculdade em que você estuda'),
      period: yup.string().required('Informe o período que você está cursando'),
    });

  return (
    <Formik
      initialValues={{
        name: userInfo ? userInfo.name : '',
        phone: userInfo ? userInfo.phone : '',
        college: userInfo ? userInfo.college : '',
        period: userInfo ? userInfo.period : '',
      }}
      enableReinitialize
      validationSchema={getValidationSchema}
      onSubmit={(values) => update(values)}
    >
      {({ values, handleSubmit, errors, touched, setFieldTouched, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <TextField
            required
            variant="outlined"
            label="Nome"
            value={values.name}
            style={{ width: '100%' }}
            onBlur={() => setFieldTouched('name')}
            onChange={(value) => setFieldValue('name', value.target.value)}
            error={!!errors.name && !!touched.name}
            helperText={errors.name && touched.name ? errors.name : undefined}
          />

          <Spacing horizontal={5} />

          <Spacing vertical={15}>
            <InputMask
              mask="(99) 99999-9999"
              value={values.phone}
              onChange={(value) => setFieldValue('phone', value.target.value)}
              onBlur={() => setFieldTouched('phone')}
            >
              {() => <TextField
                required
                variant="outlined"
                label="Telefone"
                style={{ width: '100%' }}
                error={!!errors.phone && !!touched.phone}
                helperText={errors.phone && touched.phone ? errors.phone : undefined}
              />}
            </InputMask>
          </Spacing>

          <TextField
            required
            variant="outlined"
            label="Faculdade"
            value={values.college}
            style={{ width: '100%' }}
            onBlur={() => setFieldTouched('college')}
            onChange={(value) => setFieldValue('college', value.target.value)}
            error={!!errors.college && !!touched.college}
            helperText={errors.college && touched.college ? errors.college : undefined}
          />


          <Spacing vertical={15}>
            <TextField
              required
              variant="outlined"
              label="Período"
              value={values.period}
              style={{ width: '100%' }}
              onBlur={() => setFieldTouched('period')}
              onChange={(value) => setFieldValue('period', value.target.value)}
              error={!!errors.period && !!touched.period}
              helperText={errors.period && touched.period ? errors.period : undefined}
            />
          </Spacing>

          <FormControlLabel
            control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} />}
            label="Desejo receber comunicações da HELPS e arrasar no currículo"
          />

          <Spacing bottom={30} />
          <Container fluid style={{ alignItems: 'flex-end' }}>
            <Button variant="contained" color="secondary" type="submit" style={{ textTransform: 'capitalize' }}>
              Atualizar dados
            </Button>
          </Container>
        </Form>
      )}
    </Formik>
  )
}

const PersonalDataPage: React.FC = () => {
  const { user } = useAuth();
  const { mobile } = useResize();

  const [userInfo, setUserInfo] = useState<IUserInfo>();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (user) {
        const info = await getUserInfo(user.id);

        if (info) {
          setUserInfo(info);
          setChecked(info.allowCommunication)
        }
      }
    }

    getData();
  }, [user]);


  const update = async (values: any) => {
    const { name, phone, college, period } = values || {};

    const data = {
      name,
      phone,
      college,
      period,
      allowCommunication: checked,
    }

    const response = await updateUserInfo(user?.id, data);

    if (response) {
      Toast('Dados atualizados com sucesso!', 'bottom-right', 'success');
    }
  }

  return (
    <Page>
      <Menu />
      {!mobile ? (
        <Row>
          <Container width="40%" fluid style={{ alignItems: 'flex-end' }}>
            <Spacing top={100}>
              <Container>
                <Text bold>Editar Perfil</Text>

                <Spacing top={30} bottom={10}>
                  <Link to="/dados-pessoais" style={{ textDecoration: 'none' }}>
                    <Text bold>Dados pessoais</Text>
                  </Link>
                </Spacing>

                <Link to="/dados-pessoais/login" style={{ textDecoration: 'none' }}>
                  <Text>Dados de login</Text>
                </Link>
              </Container>
            </Spacing>
          </Container>

          <Spacing horizontal={50} />

          <Container width="60%" height="100vh" >
            <Spacing top={140} />

            <Container fluid veryPadded width={400} height="100%">
              <PersonalDataForm userInfo={userInfo} checked={checked} setChecked={setChecked} update={update} />
            </Container>
          </Container>
        </Row>
      ) : (
        <Container veryPadded >
          <Text bold>Editar Perfil</Text>

          <Spacing top={30} bottom={10}>
            <Link to="/dados-pessoais">
              <Text bold>Dados pessoais</Text>
            </Link>
          </Spacing>

          <Link to="/dados-pessoais/login">
            <Text>Dados de login</Text>
          </Link>

          <Spacing top={30}>
            <PersonalDataForm userInfo={userInfo} checked={checked} setChecked={setChecked} update={update} />
          </Spacing>
        </Container>
      )}
    </Page>
  )
}

export default PersonalDataPage;