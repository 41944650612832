import React from 'react';
import ContainerStyles from './styles';
import { IContainer } from '../interfaces';

const Container: React.FC<IContainer> = ({
  fluid,
  inline,
  spacedBetween,
  spacedAround,
  spacedEvenly,
  color,
  hoverColor,
  borderColor,
  borderSize = 1,
  rounded,
  topRounded,
  rightRounded,
  bottonRounded,
  leftRounded,
  centered,
  padded,
  veryPadded,
  verticalPadded,
  veryVerticalPadded,
  horizontalPadded,
  veryHorizontalPadded,
  horizontalCentered,
  verticalCentered,
  height,
  width,
  children,
  hasShadow,
  onClick,
  onContextMenu,
  style,
}) => (
  <ContainerStyles
    verticalCentered={verticalCentered}
    horizontalCentered={horizontalCentered}
    centered={centered}
    rounded={rounded}
    topRounded={topRounded}
    rightRounded={rightRounded}
    bottonRounded={bottonRounded}
    leftRounded={leftRounded}
    fluid={fluid}
    inline={inline}
    spacedBetween={spacedBetween}
    spacedAround={spacedAround}
    spacedEvenly={spacedEvenly}
    horizontalPadded={horizontalPadded}
    verticalPadded={verticalPadded}
    padded={padded}
    veryHorizontalPadded={veryHorizontalPadded}
    veryVerticalPadded={veryVerticalPadded}
    veryPadded={veryPadded}
    color={color}
    hoverColor={hoverColor}
    borderColor={borderColor}
    borderSize={borderSize}
    height={height}
    width={width}
    onClick={onClick}
    onContextMenu={onContextMenu}
    hasShadow={hasShadow}
    style={{ ...style }}
  >
    {children}
  </ContainerStyles>
);

export default Container;
