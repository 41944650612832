import React from 'react';
import { ClipLoader } from 'react-spinners';
import Theme from '../../Theme';

import { Container } from './styles';

const LoadingScreen: React.FC = () => {
  return (
    <Container>
      <ClipLoader size={100} color={Theme.palette.secondary.main} />
    </Container>
  );
};

export default LoadingScreen;
