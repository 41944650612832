import React from 'react';
import AdminPublicRoute from './adminpublic.routes';
import AdminPrivateRoute from './adminprivate.routes';
import { useAuth } from '../hooks/auth';

const AdminRoutes: React.FC = () => {
  const { admSignedIn } = useAuth();

  if (admSignedIn) return <AdminPrivateRoute />;

  return <AdminPublicRoute />;
}

export default AdminRoutes;