import styled from 'styled-components';

interface IRowContainer {
  fluid?: boolean;

  centered?: boolean;
  spacedBetween?: boolean;
  spacedAround?: boolean;
  justifyContent?:
    | 'start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';

  alignItems?: 'center' | 'start' | 'end';
  verticallyCentered?: boolean;
  horizontallyCentered?: boolean;

  padded?: boolean;
  veryPadded?: boolean;
  horizontallyPadded?: boolean;
  verticallyPadded?: boolean;
  
  height?: number | string;
  width?: number | string;
}

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${(props: IRowContainer) => props.centered && 'justify-content: center'};
  ${({ spacedBetween }) => spacedBetween && 'justify-content: space-between'};
  ${({ spacedAround }) => spacedAround && 'justify-content: space-around'};
  ${({ justifyContent }) => `justify-content: ${justifyContent}`};

  flex: ${({ fluid }) => fluid && 1};
  align-items: ${({ alignItems }) => alignItems};

  ${({ padded }) => padded && 'padding: 10px'};
  ${({ veryPadded }) => veryPadded && 'padding: 20px'};
  ${({ horizontallyPadded }) => horizontallyPadded && 'padding: 0 10px'};
  ${({ verticallyPadded }) => verticallyPadded && 'padding: 10px 0'};

  ${({ verticallyCentered }) => verticallyCentered && 'align-items: center'};
  ${({ horizontallyCentered }) => horizontallyCentered && 'justify-content: center'};

  height: ${(props: IRowContainer) => (typeof props.height === 'number' ? `${props.height}px` : props.height)};
  width: ${(props: IRowContainer) => (typeof props.width === 'number' ? `${props.width}px` : props.width)};
`;

export default RowContainer;
