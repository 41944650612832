import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { IoMdDownload } from 'react-icons/io';
import { Container, SideMenu, Spacing, UserTable, LoadingScreen } from '../../../components';
import { getUsers } from './requests';
import moment from 'moment';

const UsersPage: React.FC = () => {
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const users = async () => {
      setLoading(true);
      const response = await getUsers();
      setUsers(response);
      setLoading(false);
    }

    users();
  }, []);

  const exportCsv = () => {
    const formatted = users.map((user: any) => ({
      nome: user.name,
      email: user.email,
      telefone: user.phone,
      universidade: user.college,
      periodo: user.period,
      cadastro_em: moment(user.created_at).format('DD/MM/yy'),
      aceita_contato: user.allow_communication ? 'SIM' : 'não',
    }));

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(formatted);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `PSU_cadastros_${moment(new Date()).format('DDMMyy_hhmmss')}` + fileExtension);
  }

  return (
    <SideMenu title="Usuários">
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <Spacing bottom={15}>
            <Container fluid style={{ alignItems: 'flex-end' }}>
              <Button variant="contained" color="secondary" onClick={() => exportCsv()} style={{ textTransform: 'capitalize' }}>
                Baixar
                <IoMdDownload />
              </Button>
            </Container>
          </Spacing >

          <UserTable users={users} />
        </>
      )}
    </SideMenu >
  )
}

export default UsersPage;