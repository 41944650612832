import React from 'react';
import ImageContainer from './styles';
import theme from '../../Theme';

interface IImage {
  source: string;
  size: number;
  color?: string;
  borderColor?: string;
}

const Image: React.FC<IImage> = ({ source, size, color = theme.palette.white.main, borderColor }) => {
  return (
    <ImageContainer size={size} color={color} borderColor={borderColor}>
      <img src={source} alt="map-pin" />
    </ImageContainer>
  );
};

export default Image;
