import React from 'react';
import theme from '../../Theme';
import Container from '../Container';

interface IPage {
  color?: string;
  padded?: boolean;
  veryPadded?: boolean;
}

const Page: React.FC<IPage> = ({ children, color, padded, veryPadded }) => {
  return (
    <Container
      fluid
      height="100vh"
      padded={padded}
      veryPadded={veryPadded}
      color={color || theme.palette.white.main}
      style={{ overflowX: 'hidden', overflowY: 'auto' }}
    >
      {children}
    </Container>
  );
};

export default Page;
