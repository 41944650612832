import styled from 'styled-components';
import { IText as ITextContainer } from '../interfaces';

const TextContainer = styled.div`
  p {
    font-family: Helvetica, sans-serif;
    color: #242424;
    color: ${(props: ITextContainer) => `${props.color}`};
    font-size: ${(props: ITextContainer) => `${props.size}px`};
    font-weight: ${(props: ITextContainer) => props.bold && 700};
    font-weight: ${(props: ITextContainer) => props.light && 200};
    text-align: ${(props: ITextContainer) => props.center && 'center'};
    text-align: ${(props: ITextContainer) => props.right && 'right'};
    line-height: 1.4;
  }
`;

export default TextContainer;
