import styled from 'styled-components';
import { IContainer } from '../interfaces';

const ContainerStyles = styled.div`
  ${(props: IContainer) => props.fluid && 'display: flex; flex-direction: column;'};
  ${(props: IContainer) => props.inline && 'display: flex; flex-direction: row;'};

  ${(props: IContainer) => props.spacedBetween && 'justify-content: space-between'};
  ${(props: IContainer) => props.spacedAround && 'justify-content: space-around'};
  ${(props: IContainer) => props.spacedEvenly && 'justify-content: space-evenly'};

  ${(props: IContainer) => props.color && `background: ${props.color}`};
  ${(props: IContainer) => props.hoverColor && `:hover{ background:${props.hoverColor};}`};
  ${(props: IContainer) => props.borderColor && `border: ${props.borderSize}px solid ${props.borderColor}`};

  ${(props: IContainer) => props.rounded && 'border-radius: 5px'};
  ${(props: IContainer) => props.topRounded && 'border-radius: 3px 3px 0px 0px'};
  ${(props: IContainer) => props.rightRounded && 'border-radius: 0px 3px 3px 0px'};
  ${(props: IContainer) => props.bottonRounded && 'border-radius: 0px 0px 3px 3px'};
  ${(props: IContainer) => props.leftRounded && 'border-radius: 3px 0px 0px 3px'};

  ${(props: IContainer) => props.centered && 'align-items: center; justify-content: center'};

  ${(props: IContainer) => props.padded && 'padding: 10px'};
  ${(props: IContainer) => props.veryPadded && 'padding: 20px'};
  ${(props: IContainer) => props.verticalPadded && 'padding: 10px 0'};
  ${(props: IContainer) => props.veryVerticalPadded && 'padding: 20px 0'};
  ${(props: IContainer) => props.horizontalPadded && 'padding: 0 10px'};
  ${(props: IContainer) => props.veryHorizontalPadded && 'padding: 0 20px'};

  ${(props: IContainer) => props.horizontalCentered && 'align-items: center'};
  ${(props: IContainer) => props.verticalCentered && 'justify-content: center'};

  height: ${(props: IContainer) => (typeof props.height === 'number' ? `${props.height}px` : props.height)};
  width: ${(props: IContainer) => (typeof props.width === 'number' ? `${props.width}px` : props.width)};

  box-shadow: ${(props: IContainer) => props.hasShadow && 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'};
`;

export default ContainerStyles;
