import React from 'react';
import {Link} from 'react-router-dom';
import Container from '../../Atoms/Container';
import Image from '../../Atoms/Image';
import logo from '../../../assets/logo.png'

const Logo: React.FC = () => {
  return (
    <Container fluid centered>
      <Link to="/">
        <Image source={logo} size={160} />
      </Link>
    </Container>
  )
}

export default Logo;