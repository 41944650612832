import React from 'react';
import moment from 'moment';
import Text from '../../Atoms/Text';
import Container from '../../Atoms/Container';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BiCheck } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import Theme from '../../Theme';

interface IScoresTable {
  scores: any[];
};

interface IUserTable {
  users: any[];
};

interface IPassingScoresTable {
  rows: any[];
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const ScoresTable: React.FC<IScoresTable> = ({ scores }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>#</StyledTableCell>
            <StyledTableCell>Data</StyledTableCell>
            <StyledTableCell>Nota</StyledTableCell>
          </TableRow >
        </TableHead >

        <TableBody>
          {scores?.map((row: any, index: number) => (
            <StyledTableRow key={index}>
              <StyledTableCell >{index + 1}</StyledTableCell>
              <StyledTableCell>{moment(row.date).format('DD/MM/yyyy HH:mm')}</StyledTableCell>
              <StyledTableCell>{row.score}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table >

      {!scores.length && (
        <Container fluid centered veryPadded>
          <Text bold center size={18}>Por enquanto não há nada por aqui :(</Text>
        </Container>
      )}
    </TableContainer >
  )
}

const PassingScoreTable: React.FC<IPassingScoresTable> = ({ rows }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Instituição</StyledTableCell>
            <StyledTableCell>Especialidade</StyledTableCell>
            <StyledTableCell>Nota de corte</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows?.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.university}
              </StyledTableCell>
              <StyledTableCell>{row.speciality}</StyledTableCell>
              <StyledTableCell>{row.score}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

      {!rows.length && (
        <Container fluid centered veryPadded>
          <Text bold center size={18}>Por enquanto não há nada por aqui :(</Text>
        </Container>
      )}
    </TableContainer>
  )
}

const StaticScores: React.FC<IPassingScoresTable> = ({ rows }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Instituição</StyledTableCell>
            <StyledTableCell>Especialidade</StyledTableCell>
            <StyledTableCell>Nota de corte</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows?.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.Hospital}
              </StyledTableCell>
              <StyledTableCell>{row.Especialidade}</StyledTableCell>
              <StyledTableCell>{row.Corte}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

      {!rows.length && (
        <Container fluid centered veryPadded>
          <Text bold center size={18}>Por enquanto não há nada por aqui :(</Text>
        </Container>
      )}
    </TableContainer>
  )
}

const UserTable: React.FC<IUserTable> = ({ users }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Nome</StyledTableCell>
            <StyledTableCell>Universidade</StyledTableCell>
            <StyledTableCell>Período</StyledTableCell>
            <StyledTableCell>E-mail</StyledTableCell>
            <StyledTableCell>Telefone</StyledTableCell>
            <StyledTableCell>Criado em</StyledTableCell>
            <StyledTableCell align="center">Aceita comunicação</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {users?.map((row: any, index: number) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{row.name}</StyledTableCell>
              <StyledTableCell>{row.college}</StyledTableCell>
              <StyledTableCell>{row.period}</StyledTableCell>
              <StyledTableCell>{row.email}</StyledTableCell>
              <StyledTableCell>{row.phone}</StyledTableCell>
              <StyledTableCell>{moment(row.created_at).format('DD/MM/yyyy')}</StyledTableCell>
              <StyledTableCell align="center">
                {row.allow_communication ?
                  <BiCheck size={18} color={Theme.palette.success.main} /> :
                  <MdClose size={18} color={Theme.palette.error.main} />}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

      {!users.length && (
        <Container fluid centered veryPadded>
          <Text bold center size={18}>Por enquanto não há nada por aqui :(</Text>
        </Container>
      )}
    </TableContainer>
  )
}

export { ScoresTable, PassingScoreTable, UserTable, StaticScores };