const colors = {
  primary: '#140204',
  secondary: '#fdd523',
  tertiary: '#1d1d1d',

  textColor: '#140204',
  background: '#eeeeee',

  white: '#FDFFFC',
  lighterGray: '#F4F4F4',
  lightGray: '#C5C5C5',
  gray: '#9C9C9C',
  darkGray: '#404040',
  black: '#140204',

  error: '#CE272A',
  success: '#27871D',
  warning: '#F8D53A',
};

const styles = { colors };

export default styles;
