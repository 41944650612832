import { admApi } from '../../../services/api';
import { handleError } from '../../../utils';

// const usersBaseUrl = '/users';
const authBaseUrl = '/account/a';

// export const createUser = async (userInfo: any) => {
//   try {
//     await admApi.post(usersBaseUrl, userInfo);
//     return true;
//   } catch (err: any) {
//     handleError(err.response?.data);
//     return false;
//   }
// };

export const signAdmIn = async (values: any) => {
  try {
    const response = await admApi.post(`${authBaseUrl}/authenticate`, values);

    if (response) {
      const { admToken, admData } = response.data;

      localStorage.setItem('@HELPS:admToken', admToken);
      localStorage.setItem('@HELPS:adm', JSON.stringify(admData));
      admApi.defaults.headers.common['Authorization'] = `Bearer ${admToken}`;

      return { admData, admToken };
    }
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export const signOut = async () => {
  await localStorage.removeItem('@HELPS:adm');
  await localStorage.removeItem('@HELPS:admToken');

  delete admApi.defaults.headers.common['Authorization'];

  return true;
};
