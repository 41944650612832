import { admApi } from '../../../services/api';
import { handleError } from '../../../utils';

export const getUsers = async () => {
  try {
    const response = await admApi.get('/users');
    return response.data;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export const getUsersByMonth = async () => {
  try {
    const response = await admApi.get('/users/month');
    return response.data;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};
