import { userApi } from '../../services/api';
import { handleError } from '../../utils';

const usersBaseUrl = 'users';

export const getUserInfo = async (user: any) => {
  try {
    const response = await userApi.get(`/${usersBaseUrl}/${user}/info`);
    return response.data;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export const updateUserInfo = async (user: any, userInfo: any) => {
  try {
    await userApi.put(`/${usersBaseUrl}/${user}/info`, userInfo);
    return true;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export const updateUserPassword = async (user: any, password: any) => {
  try {
    await userApi.put(`/${usersBaseUrl}/${user}/password`, password);
    return true;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};
