import styled from 'styled-components';

interface IContent {
  color: string;
}

const drawerWidth = '80px';

export const Content = styled.div<IContent>`
  position: fixed;
  left: 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  min-width: ${drawerWidth};
  width: ${drawerWidth};
  height: 100vh;

  background-color: ${(props) => props.color};

  z-index: 10000;
`;

export const ContentButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 100vh;

  background-color: ${(props) => props.color};
`;

export const DrawerContent = styled.div`
  left: ${drawerWidth} !important;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  min-width: 100px;
  width: 190px;
  height: 100vh;

  background-color: ${(props) => props.color};
`;

export const DrawerItemTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 50px;
  height: 50px;
  width: 100%;
`;

export const DrawerItemIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 50px;
  height: 50px;
  width: 100%;

  cursor: pointer;

  svg {
    * {
      fill: ${(props) => props.color || '#fff'};
    }
  }
`;

export const DrawerItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  min-height: 50px;
  height: 50px;
  width: 100%;

  cursor: pointer;
`;

interface IDrawerOptimumLabelProps {
  color: string;
}

export const DrawerOptimumLabel = styled.span<IDrawerOptimumLabelProps>`
  color: ${(props) => props.color};
  font-weight: bold;
  font-size: 19px;
`;

export const ContentLogoff = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  min-height: 150px;
  max-height: 150px;

  background-color: ${(props) => props.color};

  margin-bottom: 8px;
`;

export const DrawerItemLogoff = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  min-height: 50px;
  height: 50px;
  width: 100%;

  cursor: pointer;
`;

export const EnvContainer = styled.div`
  width: 100%;
  height: 50px;
`

export const EnvLabel = styled.p`
  color: #fff;

  font-size: 10px;
  font-weight: 500;

  margin-left: 5px;
`;
