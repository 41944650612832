import React, { useState } from 'react';
import { useAuth } from '../../../hooks/auth';
import { Link, useHistory } from 'react-router-dom';
import { Button, Menu, MenuItem, AppBar, Toolbar, IconButton, Drawer } from '@material-ui/core';
import { RiSettings3Fill } from 'react-icons/ri';
import Container from '../../Atoms/Container';
import Image from '../../Atoms/Image';
import Row from '../../Atoms/Row';
import Spacing from '../../Atoms/Spacing';
import Text from '../../Atoms/Text';
import Theme from '../../Theme';
import logo from '../../../assets/logo.png';
import { useResize } from '../../../hooks/resize';
import { FiMenu } from 'react-icons/fi'
import Logo from '../Logo';
import {
  ContentButtons,
  DrawerContent,
} from './styles';

const MenuComponent: React.FC = () => {
  const history = useHistory();
  const { mobile } = useResize();
  const { signUserOut } = useAuth();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!mobile ? (
        <Container width="100%" color={Theme.palette.primary.main} style={{ padding: '10px 60px' }}>
          <Row verticallyCentered spacedBetween>
            <Link to="/minhas-notas" style={{ textDecoration: 'none' }}>
              <Image source={logo} size={90} />
            </Link>

            <Container>
              <Row>
                <Link to="/minhas-notas" style={{ textDecoration: 'none' }}>
                  <Text color={window.location.href.includes('minhas-notas') ? Theme.palette.secondary.main : Theme.palette.white.main}>Minhas notas</Text>
                </Link>
                <Spacing horizontal={10} />
                {/* <Link to="/nota-de-corte" style={{ textDecoration: 'none' }}>
                  <Text color={window.location.href.includes('nota-de-corte') ? Theme.palette.secondary.main : Theme.palette.white.main}>Notas de corte</Text>
                </Link> */}
              </Row>
            </Container>

            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              <RiSettings3Fill color={Theme.palette.white.main} size={24} />
            </Button>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => history.push('/dados-pessoais')}>Editar perfil</MenuItem>
              <MenuItem onClick={() => signUserOut()}>Sair</MenuItem>
            </Menu>
          </Row>
        </Container>
      ) : (
        <>
          <AppBar position="static">
            <Toolbar style={{ background: Theme.palette.primary.main }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setOpenDrawer(!openDrawer)}
                style={{ marginRight: 2 }}
              >
                <FiMenu />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Drawer
            anchor="left"
            open={openDrawer}
            onClose={() => setOpenDrawer(!openDrawer)}
            SlideProps={{ mountOnEnter: true }}
          >
            <DrawerContent role="presentation" color={Theme.palette.primary.main}>
              <ContentButtons>
                <Spacing top={10} width="100%">
                  <Logo />
                </Spacing>

                <Spacing vertical={15}>
                  <Link to="/minhas-notas" style={{ textDecoration: 'none' }}>
                    <Text color={window.location.href.includes('minhas-notas') ? Theme.palette.secondary.main : Theme.palette.white.main}>Minhas notas</Text>
                  </Link>
                </Spacing>

                <Link to="/nota-de-corte" style={{ textDecoration: 'none' }}>
                  <Text color={window.location.href.includes('nota-de-corte') ? Theme.palette.secondary.main : Theme.palette.white.main}>Notas de corte</Text>
                </Link>

                <Spacing vertical={15}>
                  <Link to="/dados-pessoais" style={{ textDecoration: 'none' }}>
                    <Text color={window.location.href.includes('dados-pessoais') ? Theme.palette.secondary.main : Theme.palette.white.main}>Editar perfil</Text>
                  </Link>
                </Spacing>

                <Spacing top={50}>
                  <Button variant="text" onClick={() => signUserOut()} style={{ textDecoration: 'capitalize' }}>
                    <Text color={Theme.palette.secondary.main}>Sair</Text>
                  </Button>
                </Spacing>
              </ContentButtons>
            </DrawerContent>
          </Drawer>
        </>
      )}
    </>
  )
}

export default MenuComponent;