import React, { useEffect, useState } from 'react';
import { Button, IconButton, InputAdornment, TextField, OutlinedInput, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import { Container, Title, Theme, Spacing } from '../../../components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useAuth } from '../../../hooks/auth';
import { useHistory } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const AdminLoginPage: React.FC = () => {
  const history = useHistory();
  const { admSignIn, admSignedIn } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (admSignedIn) {
      history.push("/admin/dashboard")
    }
  }, [history, admSignedIn]);


  const handleLogin = async (values: any) => {
    const response = await admSignIn(values);

    if (response) {
      history.push('/admin/dashboard');
    }
  }

  return (
    <Container height="100vh" width="100%" fluid centered color={Theme.palette.primary.main}>
      <Container veryPadded rounded width={450} color={Theme.palette.white.main}>
        <Title size="regular">Calculadora PSU HELPS - Admin</Title>

        <Spacing bottom={30} />

        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          validationSchema={
            yup.object().shape({
              email: yup.string().email('Informe um e-mail válido').required('Informe seu e-mail'),
              password: yup.string().required('Informe uma senha'),
            })
          }
          enableReinitialize
          onSubmit={(values) => handleLogin(values)}
        >
          {({ values, handleSubmit, errors, touched, setFieldTouched, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <TextField
                required
                variant="outlined"
                label="E-mail"
                value={values.email}
                style={{ width: '100%' }}
                onBlur={() => setFieldTouched('email')}
                onChange={(value) => setFieldValue('email', value.target.value)}
                error={!!errors.email && !!touched.email}
                helperText={errors.email && touched.email ? errors.email : undefined}
              />

              <Spacing bottom={10} />

              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel htmlFor="password">Senha</InputLabel>

                <OutlinedInput
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  value={values.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>}
                  labelWidth={70}
                  onBlur={() => setFieldTouched('password')}
                  onChange={(value) => setFieldValue('password', value.target.value)}
                  error={!!errors.password && !!touched.password}
                />
                {errors.password && touched.password && (
                  <FormHelperText error id="accountId-error">
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>

              <Spacing bottom={10} />

              <Container fluid style={{ alignItems: 'flex-end' }}>
                <Button variant="contained" color="secondary" type="submit" style={{ textTransform: 'capitalize' }}>
                  Entrar
                </Button>
              </Container>
            </Form>
          )}
        </Formik>
      </Container>
    </Container>
  )
}

export default AdminLoginPage;