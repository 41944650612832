import React from 'react';
import { Route } from 'react-router-dom';
import UsersPage from '../pages/Admin/Users';
import DashboardPage from '../pages/Admin/Dashboard';
import PassingScorePage from '../pages/Admin/PassingScore';
import QuestionsAndAnswersPage from '../pages/Admin/QuestionsAndAnswers';

import AdminPublicRoute from './adminpublic.routes';

const AdminPrivateRoute: React.FC = () => {
  return (
    <>
      <Route path="/admin/dashboard" exact component={DashboardPage} />
      <Route path="/admin/nota-de-corte" exact component={PassingScorePage} />
      <Route path="/admin/perguntas-e-respostas" exact component={QuestionsAndAnswersPage} />
      <Route path="/admin/usuarios" exact component={UsersPage} />

      <AdminPublicRoute />
    </>
  )
}

export default AdminPrivateRoute;
