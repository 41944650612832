import { admApi } from '../../../services/api';
import { handleError } from '../../../utils';

export const getColleges = async () => {
  try {
    const response = await admApi.get('/college');
    return response.data;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export const saveQuestion = async (data: any) => {
  try {
    const response = await admApi.post('/question', data);
    return response.data;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export const editQuestion = async (questionId: string, data: any) => {
  try {
    const response = await admApi.put(`/question/${questionId}`, data);
    return response.data;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export const saveAnswers = async (questionId: string, data: any) => {
  try {
    const response = await admApi.post(`/question/${questionId}/answer`, data);
    return response.data;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export const editAnswers = async (questionId: string, data: any) => {
  try {
    const response = await admApi.put(`/question/${questionId}/answer`, data);
    return response.data;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export const getAllQuestions = async () => {
  try {
    const response = await admApi.get('/question/answer');
    return response.data;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};
