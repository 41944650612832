import { admApi } from '../../../services/api';
import { handleError } from '../../../utils';

export const getColleges = async () => {
  try {
    const response = await admApi.get('/college');
    return response.data;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export const getSpecialities = async () => {
  try {
    const response = await admApi.get('/speciality');
    return response.data;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export const savePassingScore = async (data: any) => {
  try {
    await admApi.post(`/college/${data.college}/speciality`, data);
    return true;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export const saveCollege = async (data: any) => {
  try {
    await admApi.post(`/college`, data);
    return true;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};


export const saveSpeciality = async (data: any) => {
  try {
    await admApi.post(`/speciality`, data);
    return true;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export const getAdminPassingScores = async () => {
  try {
    const response = await admApi.get('/college/speciality');
    return response.data;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};
