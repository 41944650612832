import React from 'react';
import TextContainer from './styles';
import { IText } from '../interfaces';

const Text: React.FC<IText> = ({
  children,
  bold,
  light,
  center,
  right,
  color,
  size = 16,
  style,
}) => {
  return (
    <TextContainer center={center} right={right} size={size} bold={bold} light={light} color={color} style={{...style}}>
      <p>{children}</p>
    </TextContainer>
  );
};

export default Text;
