import React, { useEffect, useState } from 'react';
import { LineChart, Line, CartesianGrid, Tooltip, XAxis, BarChart, Bar, YAxis } from 'recharts'
import { Container, SideMenu, Text, Row, Spacing, Theme, Title, LoadingScreen } from '../../../components';
import { getUsers, getUsersByMonth } from '../Users/requests';
import moment from 'moment';

const DashboardPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [usersByPeriod, setUsersByPeriod] = useState<any[]>([]);
  const [usersByMonth, setUsersByMonth] = useState<any[]>([]);

  useEffect(() => {
    const users = async () => {
      setLoading(true);
      const allUsers = await getUsers();
      const byMonth = await getUsersByMonth();
      setUsers(allUsers);
      setUsersByMonth(byMonth);
      setLoading(false);
    }

    users();
  }, []);

  useEffect(() => {
    if (users.length) {
      let array: any[] = [];

      users.forEach((user: any) => {
        if (!array.length) {
          array.push({
            period: user.period,
            quantidade: 1,
            label: user.period === 13 ? 'Formado(a)' : user.period
          });
        } else {
          const index = array.findIndex((item: any) => item.period === user.period);

          if (index >= 0) {
            array[index] = {
              period: array[index].period,
              quantidade: array[index].quantidade + 1,
              label: array[index].label
            }
          } else {
            array.push({
              period: user.period,
              quantidade: 1,
              label: user.period === 13 ? 'Formado(a)' : user.period
            });
          }
        }
      })

      array.sort((a, b) => a.period < b.period ? -1 : 1);

      setUsersByPeriod(array);
    }
  }, [users]);

  useEffect(() => {
    if (usersByMonth.length) {
      let array: any[] = [];

      usersByMonth.forEach((user: any) => {
        if (!array.length) {
          array.push({ period: moment(user.created_at).format('MMM'), quantidade: 1, month: new Date(user.created_at).getMonth() });
        } else {
          const index = array.findIndex((item: any) => item.period === moment(user.created_at).format('MMM'));

          if (index >= 0) {
            array[index] = { period: array[index].period, quantidade: array[index].quantidade + 1, month: new Date(user.created_at).getMonth() }
          } else {
            array.push({ period: moment(user.created_at).format('MMM'), quantidade: 1, month: new Date(user.created_at).getMonth() })
          }
        }
      })

      array.sort((a, b) => a.month < b.month ? -1 : 1);
      setFilteredUsers(array);
    }
  }, [usersByMonth]);

  return (
    <SideMenu title="Dashboard">
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <Spacing bottom={10}>
            <Container veryPadded color={Theme.palette.white.main} rounded width={180}>
              <Text bold size={36}>{users.length}</Text>
              <Text color={Theme.palette.gray.main}>Se cadastraram na calculadora</Text>
            </Container>
          </Spacing>

          <Row>
            <Container padded rounded color={Theme.palette.white.main}>
              <Spacing bottom={10} left={8}>
                <Title size="small">Estudantes de medicina por semestre</Title>
              </Spacing>

              <LineChart
                width={600}
                height={400}
                data={usersByPeriod}
                margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
                style={{ background: Theme.palette.white.main, borderRadius: 10 }}
              >
                <Tooltip />
                <XAxis dataKey="label" />
                <YAxis />
                <CartesianGrid stroke="#f5f5f5" />
                <Line connectNulls type="monotone" dataKey="quantidade" stroke={Theme.palette.warning.main} fill={Theme.palette.warning.main} />
              </LineChart>
            </Container>

            <Spacing horizontal={15} />

            <Container padded color={Theme.palette.white.main} rounded>
              <Spacing bottom={10} left={8}>
                <Title size="small">Usuários cadastrados nos últimos 6 meses</Title>
              </Spacing>

              <BarChart
                width={500}
                height={400}
                data={filteredUsers}
                style={{ background: Theme.palette.white.main, borderRadius: 10 }}
                margin={{ top: 10, right: 10, left: 0, bottom: 10 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="period" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="quantidade" fill="#8884d8" />
              </BarChart>
            </Container>
          </Row>
        </>
      )}

    </SideMenu >
  )
}

export default DashboardPage;