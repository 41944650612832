import React from 'react';
import { Route } from 'react-router-dom';
import LoginPage from '../pages/Admin/Auth';

const AdminPublicRoute: React.FC = () => {
  return (
    <>
      <Route path="/admin" exact component={LoginPage} />
    </>
  )
}

export default AdminPublicRoute;
