import { userApi } from '../../services/api';
import { handleError } from '../../utils';

const usersBaseUrl = '/users';
const authBaseUrl = '/account/u';

export const createUser = async (userInfo: any) => {
  try {
    await userApi.post(usersBaseUrl, userInfo);
    return true;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export const signIn = async (values: any) => {
  try {
    const response = await userApi.post(`${authBaseUrl}/authenticate`, values);

    if (response) {
      const { token, userData } = response.data;

      localStorage.setItem('@HELPS:token', token);
      localStorage.setItem('@HELPS:usr', JSON.stringify(userData));
      userApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      return { userData, token };
    }
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export const signOut = async () => {
  await localStorage.removeItem('@HELPS:usr');
  await localStorage.removeItem('@HELPS:token');

  delete userApi.defaults.headers.common['Authorization'];

  return true;
};

export const findUserByEmailAndPassword = async (data: any) => {
  try {
    const response = await userApi.post(`${authBaseUrl}/request-new-password`, data);
    return response.data;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export const changePassword = async (data: any) => {
  try {
    await userApi.post(`${authBaseUrl}/forgot-password`, data);
    return true;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};
