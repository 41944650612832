import React from 'react';
import { Container, Spacing } from '../../components';

const PrivacyPolicy: React.FC = () => {
  return (
    <Container style={{ margin: 30 }}>
      <h1>
        POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS
      </h1>
      <Spacing top={20} />
      <h2>
        1.	INFORMAÇÕES GERAIS
      </h2>
      <ol type="a">
        <li>
          Nos preocupamos e respeitamos sua privacidade e a proteção de seus dados. Esta Política de Privacidade e Proteção de Dados Pessoais (“Politica”), contém informações sobre coleta, uso, armazenamento, tratamento e proteção dos seus dados pessoais pela HELPS CONSULTORIA LTDA (“HELPS”), seja pelo site ou qualquer outro meio citado neste documento.
          Esta Política se aplica a todos usuários do site HELPSMED, desenvolvido pela HELPS, empresa pertencente ao Grupo HN Educacional, devidamente inscrita no CNPJ sob o nº 42.035.943/0001-08, com sede em Betim/MG e se estende a links acessados através de todas ou qualquer uma de nossas redes sociais oficiais por todo e qualquer usuário, seja utilizador do site HELPSMED ou não.
        </li>
      </ol>
      <Spacing top={20} />
      <h2>
        2.	COMO COLETAMOS SEUS DADOS PESSOAIS?
      </h2>
      <p>A HELPS poderá solicitar seus dados pessoais nas seguintes hipóteses:</p>
      <ol type="a">
        <li>Quando você adquirir um dos serviços ofertados no nosso site;</li>
        <li>Quando você acessa nosso site e aceita o uso de cookies;</li>
        <li>Quando você preencher um formulário para receber algum conteúdo exclusivo ofertado pela HELPS;</li>
        <li>Quando você entrar em contato conosco por telefone, whatsapp ou e-mail;</li>
        <li>Ao entrar em contato conosco por nossas redes sociais oficiais;</li>
      </ol>
      <Spacing top={20} />
      <h2>
        3. QUAIS DADOS PESSOAIS E PARA QUAIS FINALIDADES COLETAMOS?
      </h2>
      <ol type="a">
        <li>
          Toda coleta de dados está fundamentada nos princípios da finalidade, necessidade, transparência, segurança, prevenção, não discriminação, qualidade dos dados, adequação, livre acesso e prestação de contas.
        </li>

        <li>
          Para que você consiga adquirir um ou mais serviços oferecidos pela HELPS, coletaremos informações para cadastro, como nome completo, endereço, CPF, e-mail e telefone. Solicitaremos também o upload dos seus certificados para inclusão nos currículos a serem confeccionados ou analisados. A base legal para o tratamento desses dados é a Execução de Contrato.
        </li>

        <li>
          Quando você faz o pagamento ao adquirir um serviço, coletamos dados referentes ao pagamento e transações, tais como: número do cartão de crédito, quantidade e valor das parcelas e o nome do titular do cartão. A base legal para tratamento desses dados é a execução de contrato.
        </li>

        <li>
          Para prestar o serviço de confecção de currículo Lattes, coletaremos o seu login e senha e solicitaremos também todos os dados pessoais que componham o currículo. A base legal para a coleta e tratamento desses dados é a Execução de contrato. Mas fique tranquilo, esses dados não são armazenados em nossos bancos de dados e somente o mentor designado a você terá esse acesso. E recomendamos que você altere a sua senha após a conclusão do serviço.
        </li>

        <li>
          Podemos coletar seu comportamento durante a utilização do nosso site, como em quais botões você clicou e quais páginas acessou para compreender como você utiliza nosso site, personalizar o conteúdo oferecido e para análises de marketing. Esses dados são coletados quando você aceita todos os cookies utilizados pelo nosso site. Esses dados são criptografados e anonimizados, e você pode negar a coleta ou revogar a autorização dada. Utilizamos o consentimento como base legal para tratamento desses dados.
          Para saber mais sobre a captação, demais finalidades de utilização e como revogar a autorização de coleta, acesse a nossa Política de Cookies.

        </li>

        <li>
          Caso você tenha o interesse de receber algum conteúdo exclusivo ofertado por nós em nosso site ou redes sociais oficiais, poderemos coletar dados como nome e e-mail, telefone, instituição de ensino e período de curso. Esses dados serão utilizados para o envio dos conteúdos exclusivos ofertados.  A base para o tratamento desses dados é o consentimento
        </li>

        <li>
          Quando você nos envia uma mensagem via WhatsApp, podemos coletar o seu nome e telefone para conseguirmos atender suas solicitações. A base legal utilizada é o consentimento.
        </li>

        <li>
          Quando você nos envia um e-mail, podemos coletar o seu nome e endereço de e-mail para conseguirmos atender suas solicitações. A base legal utilizada é o consentimento.
        </li>

        <li>
          Atenção: O tratamento de dados pessoais para finalidades não previstas nesta Política poderá ocorrer dentro dos parâmetros permitidos por lei, mas o usuário poderá se opor a esse tratamento a qualquer momento seguindo as orientações fornecidas no tópico 8 desta Política, onde se destacam os direitos do titular.
        </li>
      </ol>
      <Spacing top={20} />
      <h2>
        4.	POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?
      </h2>
      <ol type="a">
        <p>
          Seus dados pessoais ficarão armazenados durante o período necessário para a prestação do serviço ou o cumprimento das finalidades previstas no presente documento, conforme o disposto no inciso I do artigo 15 da Lei 13.709/18. Você poderá solicitar a remoção ou anonimização dos dados, exceto os casos em que a lei oferecer outro tratamento.
          Ainda, seus dados pessoais apenas poderão ser conservados após o término de seu tratamento nas seguintes hipóteses previstas no artigo 16 da referida lei:
        </p>
        <li>
          Cumprimento de obrigação legal ou regulatória pelo controlador;
        </li>

        <li>
          Estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;
        </li>

        <li>
          Transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei;
        </li>

        <li>
          Uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.
        </li>
      </ol>
      <Spacing top={20} />
      <h2>
        5. SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS
      </h2>
      <ol type="a">
        <li>
          A HELPS se compromete a aplicar as medidas técnicas e organizativas aptas a proteger seus dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão. Também nos comprometeremos a lhe comunicar a ocorrência de caso alguma violação de segurança envolvendo seus dados pessoais.
        </li>

        <li>
          Utilizamos a tecnologia "secure socket layer" (SSL) que garante a transmissão de dados de forma segura e confidencial, de modo que a transmissão dos dados entre o servidor e você ocorra de maneira cifrada e encriptada.
        </li>

        <li>
          Também utilizamos um conjunto de serviços que visam proteger a confiabilidade, integridade e segurança dos seus dados pessoais.
        </li>

        <li>
          Embora a HELPS utilize medidas de segurança e monitore seu sistema para verificar vulnerabilidades e ataques para proteger seus Dados Pessoais contra divulgação não autorizada, mau uso ou alteração, você entende e concorda que não há garantias de que as informações não poderão ser acessadas, divulgadas, alteradas ou destruídas por violação de qualquer uma das proteções físicas, técnicas ou administrativas.
        </li>

        <li>
          Seus dados pessoais armazenados são tratados com confidencialidade, dentro dos limites legais. No entanto, podemos divulgar suas informações pessoais caso sejamos obrigados por lei a fazê-lo.
        </li>
      </ol>
      <Spacing top={20} />
      <h2>
        6.	OS DADOS PESSOAIS ARMAZENADOS SERÃO COMPARTILHADOS COM TERCEIROS?
      </h2>
      <ol type="a">
        <li>
          Nos preocupamos em tratar apenas os dados que entendemos serem os mínimos necessários para cada finalidade apresentada e, em razão disso, nos comprometemos a compartilhar seus dados apenas com entidades públicas ou privadas que prestem algum tipo de serviço essencial para a execução dos serviços e/ou produtos prestados pela HELPSMED.
        </li>

        <li>
          Você pode conferir abaixo com quais empresas compartilhamos seus dados pessoais, quais dados compartilhamos e qual finalidade do compartilhamento:
        </li>

        <li>
          E-REDE (operador):
          Compartilhamos os seus dados pessoais de cadastro e dados financeiros, como os dados do seu cartão, para concretização e registro de pagamentos feitos por você. A base legal para o compartilhamento é a execução de contrato firmado entre você e a empresa.
        </li>

        <li>
          VINDI (operador):
          Compartilhamos os seus dados pessoais de cadastro e dados financeiros, como os dados do seu cartão, para concretização e registro de pagamentos feitos por você. A base legal para o compartilhamento é a execução de contrato firmado entre você e a empresa.

        </li>

        <li>
          Click Sign (operador):
          Compartilhamos o contrato firmado entre a HELPS e você para a assinatura digital do contrato. A base legal para o compartilhamento é a execução de contrato firmado entre você e a empresa.

        </li>

        <li>
          Chat 2desk (operador):
          Poderemos compartilhar dados como nome, telefone, e-mail e conversas que você tenha com nossos atendentes, para que você consiga se comunicar conosco através de ferramentas como WhatsApp e Messenger. A base legal utilizada para esse compartilhamento é o consentimento.

        </li>

        <li>
          RD STATION (operador):
          Poderemos compartilhar seus dados pessoais para gerenciamento de marketing. A base legal para compartilhamento é o legitimo interesse da empresa.

        </li>

        <li>
          Ommie (operador)
          Compartilhamos os seus dados pessoais de cadastro e dados fiscais para gerar e armazenar notas fiscais, a base legal para o compartilhamento é a obrigação legal.
        </li>

        <li>
          A HELPS também poderá compartilhar seus dados com empresas do Grupo HN Educacional para atender os legítimos interesses da empresa. Você pode verificar quais empresas compõem o Grupo HN Educacional através do link: https://hneducacional.com/
        </li>
      </ol>
      <Spacing top={20} />
      <h2>
        7.	COOKIES OU DADOS DE NAVEGAÇÃO
      </h2>
      <ol type="a">
        <li>
          Você pode saber mais sobre cookies e entender nossa política de cookies, clicando aqui.
        </li>
      </ol>
      <Spacing top={20} />
      <h2>
        8.	DIREITOS DO TITULAR
      </h2>
      <p>Como titular de dados, você poderá exercer os direitos citados no Capítulo III da Lei Geral de Proteção de Dados (L13709).
        Para maior entendimento, aconselhamos a leitura da lei na integra.
        São direitos do titular de dados:
      </p>
      <ol type="a">
        <li>
          1.	Confirmação – Você poderá confirmar se há tratamento dos seus dados pela 	HELPS. Para exercer esse direito de forma imediata, você deverá entrar em contato através do telefone (31) 99138-0738.
        </li>

        <li>
          2.	Acesso aos dados – Você poderá solicitar a cópia dos dados que possuímos sobre você. Para isso, encaminhe um e-mail com sua solicitação. O prazo para recebimento dos dados é de 15 dias a contar da data do recebimento da solicitação.
        </li>

        <li>
          3.	Retificação de dados – Você poderá corrigir de forma imediata todos os dados pessoais, em sua área do aluno, exceto o CPF por ser necessário validação humana de identidade, portanto para correção do CPF, entre em contato conosco por e-mail que daremos sequência a validação e alteração. O prazo para correção é de 1 dia útil.
        </li>
        <li>
          4.	Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na lei – para isso, encaminhe sua solicitação por e-mail. O prazo para resolução é de 2 dias úteis.
        </li>

        <li>
          5.	Portabilidade de dados – Você poderá solicitar a portabilidade dos seus dados pessoais tratados pela HELPS para qualquer outra empresa do mesmo ramo. No entanto, não poderemos fazer transferência de carteirinhas, certificados ou declarações. Para isso, envie sua solicitação por e-mail.
        </li>

        <li>
          6.	Eliminação de dados tratados com o seu consentimento – Para isso, nos encaminhe sua solicitação por e-mail. O prazo para a resolução é de 2 dias uteis.
        </li>

        <li>
          7.	Informações das entidades públicas e privadas com quem os dados foram compartilhados – Essas informações já estão descritas nesta política.
        </li>

        <li>
          8.	Revogar o seu consentimento - Para isso, você poderá clicar em botões de descadastro de envio de comunicação ou nos enviar um e-mail com sua solicitação.
        </li>

        <li>
          9.	Revisão das decisões automatizadas – Você pode solicitar a revisão das decisões que tomarmos de forma automatizada (sem atendimento humano). Para isso, no encaminhe um e-mail com sua solicitação.
        </li>

        <p>Atenção, as solicitações deverão ser encaminhadas para o endereço de e-mail compliance@helpsmed.com.br.</p>
      </ol>
      <Spacing top={20} />
      <h2>
        9.	ALTERAÇÕES PARA ESSA POLÍTICA
      </h2>
      <ol type="a">
        <li>
          Reservamos o direito de modificar essa Política a qualquer momento e em caso de alteração, o você será avisado em sua próxima visita ao site HELPSMED.
          As alterações e esclarecimentos surtirão efeito imediatamente após a publicação. Ao utilizar o serviço ou fornecer informações pessoais após eventuais modificações, você demonstra concordância com as novas normas.

        </li>

        <li>
          Diante da fusão ou venda da plataforma à outra empresa seus dados podem ser transferidos para os novos proprietários para a permanência dos serviços oferecidos.
        </li>
      </ol>
      <Spacing top={20} />
      <h2>
        10.	ENCARREGADO DE DADOS (DPO)
      </h2>
      <ol type="a">
        <li>
          Na HELPS, a encarregada de dados (DPO – Data Protection Officer) é Daiane Talita Oliveira dos Santos, que poderá ser contactada pelo e-mail: compliance@helpsmed.com.br para esclarecimento de dúvidas ou atendimento de solicitações relacionadas ao tratamento de seus dados pessoais.
        </li>
      </ol>
      <Spacing top={20} />
      <h2>
        11.	REDES SOCIAIS OFICIAIS
      </h2>
      <p>Ao ler e concordar com esta política, você declara ciência de que nossas redes sociais oficiais são:</p>
      <ol type="a">
        <li>
          Instagram: helps_med - https://www.instagram.com/helps_med/
        </li>

        <li>
          Facebook: Helps Soluções Acadêmicas - https://www.facebook.com/helpssolucoesacademicas
        </li>

        <li>
          YouTube: HELPS, Medicina! - https://www.youtube.com/channel/UClC4xVeDJnswC-tF2KgoonA?view_as=subscriber
        </li>
        <p>
          Não são regidas por esta Política quaisquer outras redes sociais não mencionadas nesta Política.
        </p>
      </ol>
      <Spacing top={20} />
      <h2>
        12.	JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS
      </h2>
      <ol type="a">
        <li>
          Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente o Direito brasileiro. Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a sede da empresa.
        </li>
      </ol>
    </Container>
  )
}

export default PrivacyPolicy;