import { userApi } from '../../services/api';
import { handleError } from '../../utils';

const baseUrl = '/question/answer';

const getQuestionsAndAnswers = async () => {
  try {
    const response = await userApi.get(baseUrl);
    return response.data;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

const saveScore = async (scoreInfo: any) => {
  const { id, score } = scoreInfo || {};

  try {
    await userApi.post(`/users/${id}/final-score`, { score });
    return true;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export { getQuestionsAndAnswers, saveScore };
