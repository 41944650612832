import { createTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import styles from './styles';

const theme = createTheme(
  {
    palette: {
      primary: { main: styles.colors.primary },
      secondary: { main: styles.colors.secondary },
      tertiary: { main: styles.colors.tertiary },
      background: { default: styles.colors.background },

      error: { main: styles.colors.error },
      success: { main: styles.colors.success },

      white: { main: styles.colors.white },
      lighterGray: { main: styles.colors.lighterGray },
      lightGray: { main: styles.colors.lightGray },
      gray: { main: styles.colors.gray },
      darkGray: { main: styles.colors.darkGray },
      black: { main: styles.colors.black },
    },
    typography: {
      fontFamily: ['Work Sans'].join(','),
    },
  },
  ptBR
);

export default theme;
export { styles };
