import React, { useState } from 'react';
import { Button, IconButton, InputAdornment, TextField, OutlinedInput, FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { Container, Row, HomeSideDiv, Text, Title, Image, Spacing, Toast } from '../../../components';
import { Form, Formik } from 'formik';
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import { useResize } from '../../../hooks/resize';
import { useHistory } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { findUserByEmailAndPassword, changePassword } from '../requests';
import calcLogo from '../../../assets/calculadora_PSU-4.png';

interface IUser {
  user: string;
  name: string;
}

const ForgotPasswordPage: React.FC = () => {
  const history = useHistory();
  const { mobile } = useResize();

  const [user, setUser] = useState<IUser>();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const validate = async (values: any) => {
    const { email, phone } = values || {};

    const response = await findUserByEmailAndPassword({ email: email.toLowerCase(), phone });

    if (response) {
      setUser(response);
    }
  }

  const saveNewPassword = async (values: any) => {
    if (values.newPassword !== values.confirmNewPassword) {
      Toast('As duas senhas não são idênticas... Verifique e tente novamente.');
      return;
    }

    const { newPassword, confirmNewPassword } = values || {};

    const response = await changePassword({ user: user?.user, newPassword, confirmNewPassword });

    if (response) {
      Toast('Senha atualizada com sucesso!', 'bottom-right', 'success');
      history.push('/');
    }
  }

  return (
    <>
      {!mobile ? (
        <Row>
          <HomeSideDiv />

          {user?.user && user?.name ? (
            <Container width="75%" fluid centered>
              <Container veryPadded width={400} style={{ margin: 'auto' }}>
                <Title size="regular">Redefina sua senha</Title>

                <Spacing vertical={15}>
                  <Text>Informe sua nova senha:</Text>
                </Spacing>

                <Formik
                  initialValues={{
                    newPassword: '',
                    confirmNewPassword: '',
                  }}
                  enableReinitialize
                  validationSchema={
                    yup.object().shape({
                      confirmNewPassword: yup.string().required('Informe sua nova senha'),
                      newPassword: yup.string().required('Confirme sua nova senha'),
                    })
                  }
                  onSubmit={(values) => saveNewPassword(values)}
                >
                  {({ values, handleSubmit, errors, touched, setFieldTouched, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                      <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel htmlFor="password">Nova senha</InputLabel>

                        <OutlinedInput
                          id="newPassword"
                          type={showNewPassword ? 'text' : 'password'}
                          value={values.newPassword}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle newPassword visibility"
                                onClick={() => setShowNewPassword(!showNewPassword)}
                                edge="end"
                              >
                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>}
                          labelWidth={70}
                          onBlur={() => setFieldTouched('newPassword')}
                          onChange={(value) => setFieldValue('newPassword', value.target.value)}
                          error={!!errors.newPassword && !!touched.newPassword}
                        />
                        {errors.newPassword && touched.newPassword && (
                          <FormHelperText error id="accountId-error">
                            {errors.newPassword}
                          </FormHelperText>
                        )}
                      </FormControl>

                      <Spacing vertical={10} />

                      <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel htmlFor="password">Confirme sua nova senha</InputLabel>

                        <OutlinedInput
                          id="confirmNewPassword"
                          type={showConfirmNewPassword ? 'text' : 'password'}
                          value={values.confirmNewPassword}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle confirmNewPassword visibility"
                                onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                                edge="end"
                              >
                                {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>}
                          labelWidth={70}
                          onBlur={() => setFieldTouched('confirmNewPassword')}
                          onChange={(value) => setFieldValue('confirmNewPassword', value.target.value)}
                          error={!!errors.confirmNewPassword && !!touched.confirmNewPassword}
                        />
                        {errors.confirmNewPassword && touched.confirmNewPassword && (
                          <FormHelperText error id="accountId-error">
                            {errors.confirmNewPassword}
                          </FormHelperText>
                        )}
                      </FormControl>

                      <Spacing bottom={30} />

                      <Button variant="contained" type="submit" color="secondary" style={{ textTransform: 'capitalize' }}>
                        Alterar minha senha
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Container>
            </Container>
          ) : (
            <Container width="75%" fluid centered>
              <Container veryPadded width={400} style={{ margin: 'auto' }}>
                <Title size="regular">Esqueceu sua senha?</Title>

                <Spacing vertical={15}>
                  <Text>Vamos redefinir sua senha! Para darmos início, digite o e-mail e o telefone que você informou ao se cadastrar na Calculadora HELPS:</Text>
                </Spacing>

                <Formik
                  initialValues={{
                    email: '',
                    phone: '',
                  }}
                  enableReinitialize
                  validationSchema={
                    yup.object().shape({
                      phone: yup.string().required('Informe seu telefone'),
                      email: yup.string().email('Informe um e-mail válido').required('Informe seu e-mail'),
                    })
                  }
                  onSubmit={(values) => validate(values)}
                >
                  {({ values, handleSubmit, errors, touched, setFieldTouched, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                      <TextField
                        required
                        variant="outlined"
                        label="E-mail"
                        value={values.email}
                        style={{ width: '100%' }}
                        onBlur={() => setFieldTouched('email')}
                        onChange={(value) => setFieldValue('email', value.target.value)}
                        error={!!errors.email && !!touched.email}
                        helperText={errors.email && touched.email ? errors.email : undefined}
                      />

                      <Spacing vertical={10} />

                      <InputMask
                        mask="(99) 99999-9999"
                        value={values.phone}
                        onChange={(value) => setFieldValue('phone', value.target.value)}
                        onBlur={() => setFieldTouched('phone')}
                      >
                        {() => <TextField
                          required
                          variant="outlined"
                          label="Telefone"
                          style={{ width: '100%' }}
                          error={!!errors.phone && !!touched.phone}
                          helperText={errors.phone && touched.phone ? errors.phone : undefined}
                        />}
                      </InputMask>

                      <Spacing bottom={30} />

                      <Button variant="contained" type="submit" color="secondary" style={{ textTransform: 'capitalize' }}>
                        Validar meus dados
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Container>
            </Container >
          )}
        </Row >
      ) : (
        <>
          {user?.user && user?.name ? (
            <Container veryPadded>
              <Container fluid centered>
                <Image source={calcLogo} size={250} />
              </Container>

              <Title size="regular">Redefina sua senha</Title>

              <Spacing vertical={15}>
                <Text>Informe sua nova senha:</Text>
              </Spacing>

              <Formik
                initialValues={{
                  newPassword: '',
                  confirmNewPassword: '',
                }}
                enableReinitialize
                validationSchema={
                  yup.object().shape({
                    confirmNewPassword: yup.string().required('Informe sua nova senha'),
                    newPassword: yup.string().required('Confirme sua nova senha'),
                  })
                }
                onSubmit={(values) => saveNewPassword(values)}
              >
                {({ values, handleSubmit, errors, touched, setFieldTouched, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <InputLabel htmlFor="password">Nova senha</InputLabel>

                      <OutlinedInput
                        id="newPassword"
                        type={showNewPassword ? 'text' : 'password'}
                        value={values.newPassword}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle newPassword visibility"
                              onClick={() => setShowNewPassword(!showNewPassword)}
                              edge="end"
                            >
                              {showNewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>}
                        labelWidth={70}
                        onBlur={() => setFieldTouched('newPassword')}
                        onChange={(value) => setFieldValue('newPassword', value.target.value)}
                        error={!!errors.newPassword && !!touched.newPassword}
                      />
                      {errors.newPassword && touched.newPassword && (
                        <FormHelperText error id="accountId-error">
                          {errors.newPassword}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <Spacing vertical={10} />

                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <InputLabel htmlFor="password">Confirme sua nova senha</InputLabel>

                      <OutlinedInput
                        id="confirmNewPassword"
                        type={showConfirmNewPassword ? 'text' : 'password'}
                        value={values.confirmNewPassword}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle confirmNewPassword visibility"
                              onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                              edge="end"
                            >
                              {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>}
                        labelWidth={70}
                        onBlur={() => setFieldTouched('confirmNewPassword')}
                        onChange={(value) => setFieldValue('confirmNewPassword', value.target.value)}
                        error={!!errors.confirmNewPassword && !!touched.confirmNewPassword}
                      />
                      {errors.confirmNewPassword && touched.confirmNewPassword && (
                        <FormHelperText error id="accountId-error">
                          {errors.confirmNewPassword}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <Spacing bottom={30} />

                    <Button variant="contained" type="submit" color="secondary" style={{ textTransform: 'capitalize' }}>
                      Alterar minha senha
                    </Button>
                  </Form>
                )}
              </Formik>
            </Container>
          ) : (
            <Container veryPadded >
              <Container fluid centered>
                <Image source={calcLogo} size={250} />
              </Container>

              <Title size="regular">Esqueceu sua senha?</Title>

              <Spacing vertical={15}>
                <Text>Vamos redefinir sua senha! Para darmos início, digite o e-mail e o telefone que você informou ao se cadastrar na Calculadora HELPS:</Text>
              </Spacing>

              <Formik
                initialValues={{
                  email: '',
                  phone: '',
                }}
                enableReinitialize
                validationSchema={
                  yup.object().shape({
                    phone: yup.string().required('Informe seu telefone'),
                    email: yup.string().email('Informe um e-mail válido').required('Informe seu e-mail'),
                  })
                }
                onSubmit={(values) => validate(values)}
              >
                {({ values, handleSubmit, errors, touched, setFieldTouched, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <TextField
                      required
                      variant="outlined"
                      label="E-mail"
                      value={values.email}
                      style={{ width: '100%' }}
                      onBlur={() => setFieldTouched('email')}
                      onChange={(value) => setFieldValue('email', value.target.value)}
                      error={!!errors.email && !!touched.email}
                      helperText={errors.email && touched.email ? errors.email : undefined}
                    />

                    <Spacing vertical={10} />

                    <InputMask
                      mask="(99) 99999-9999"
                      value={values.phone}
                      onChange={(value) => setFieldValue('phone', value.target.value)}
                      onBlur={() => setFieldTouched('phone')}
                    >
                      {() => <TextField
                        required
                        variant="outlined"
                        label="Telefone"
                        style={{ width: '100%' }}
                        error={!!errors.phone && !!touched.phone}
                        helperText={errors.phone && touched.phone ? errors.phone : undefined}
                      />}
                    </InputMask>

                    <Spacing bottom={30} />

                    <Button variant="contained" type="submit" color="secondary" style={{ textTransform: 'capitalize' }}>
                      Validar meus dados
                    </Button>
                  </Form>
                )}
              </Formik>
            </Container>
          )}
        </>
      )}
    </>

  )
}

export default ForgotPasswordPage;