import { userApi } from '../../services/api';
import { handleError } from '../../utils';

const baseUrl = 'users';

const getScores = async (userId: any) => {
  try {
    const response = await userApi.get(`${baseUrl}/${userId}/final-scores`);

    return response.data;
  } catch (err: any) {
    handleError(err.response?.data);
    return false;
  }
};

export { getScores };
