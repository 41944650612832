import React from 'react';
import { Modal as MuiModal, Backdrop } from '@material-ui/core';

interface IModal {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactElement;
}

const Modal: React.FC<IModal> = ({ children, isOpen = false, onClose }) => {
  return (
    <MuiModal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', zIndex: 0 }}
    >
      {children}
    </MuiModal>
  );
};

export default Modal;
