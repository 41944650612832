import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, } from '@material-ui/core';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Container, AskForHelps, Page, Image, Menu, Row, Spacing, Text, Theme, LoadingScreen } from '../../components';
import { makeStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { getQuestionsAndAnswers, saveScore } from './requests';
import { useAuth } from '../../hooks/auth';
import { useResize } from '../../hooks/resize';
import { maximumGradePerGroup } from '../../utils';
import medicine from '../../assets/medicine.svg';

interface IAnswer {
  id: string;
  name: string;
  score: number;
}

interface IQuestion {
  question: string;
  answers: IAnswer[];
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

interface IResults {
  sum: number;
  redo: () => void;
}

const Results: React.FC<IResults> = ({ sum, redo }) => {
  const { mobile } = useResize();

  return (
    <Container fluid centered width="100%" height="100%">
      {!mobile ? (
        <Row verticallyCentered>
          <Spacing right={50}>
            <Image source={medicine} size={350} />
          </Spacing>

          <Container fluid centered >
            <Text color={Theme.palette.gray.main}>Sua nota é</Text>
            <Text bold size={58}>{sum}</Text>

            <AskForHelps />

            <Row>
              <Spacing right={20}>
                <Link to="/questionario" onClick={() => redo()} style={{ color: Theme.palette.gray.main }}>
                  <Text color={Theme.palette.gray.main}>Refazer o teste</Text>
                </Link>
              </Spacing>

              <Link to="/minhas-notas" style={{ color: Theme.palette.gray.main }}>
                <Text color={Theme.palette.gray.main}>Voltar para a página inicial</Text>
              </Link>
            </Row>
          </Container>
        </Row>
      ) : (
        <Container fluid centered >
          <Image source={medicine} size={250} />

          <Text color={Theme.palette.gray.main}>Sua nota é</Text>
          <Text bold size={58}>{sum}</Text>

          <AskForHelps />

          <Spacing bottom={10}>
            <Link to="/questionario" onClick={() => redo()} style={{ color: Theme.palette.gray.main }}>
              <Text color={Theme.palette.gray.main}>Refazer o teste</Text>
            </Link>
          </Spacing>

          <Link to="/minhas-notas" style={{ color: Theme.palette.gray.main }}>
            <Text color={Theme.palette.gray.main}>Voltar para a página inicial</Text>
          </Link>

        </Container>
      )}
    </Container>
  )
}

const QuestionsPage: React.FC = () => {
  const { user } = useAuth();
  const { mobile } = useResize();
  const classes = useStyles();
  const history = useHistory();

  const [sum, setSum] = useState<number>(0);
  const [showResults, setShowResults] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questions, setQuestions] = useState<IQuestion[][]>([]);
  const [answers, setAnswers] = useState<(number | null)[][]>(Array(questions.length).fill(null));

  useEffect(() => {
    const getData = async () => {
      const response = await getQuestionsAndAnswers();

      if (response) {
        const questionsByGroup = response.reduce((groupsSoFar: any, { group, question, id, answers }: any) => {
          if (!groupsSoFar[group]) groupsSoFar[group] = [];
          groupsSoFar[group].push({ question, id, answers });
          return groupsSoFar;
        }, {});

        const entries = Object.entries(questionsByGroup);
        const arrays: IQuestion[][] = entries.map((item: any) => item[1]);

        setQuestions(arrays);
      }
    }

    getData();
  }, []);

  useEffect(() => {
    if (questions.length) {
      const auxAnswers = Array(questions.length).fill([]);

      auxAnswers.forEach((answer: any, index: number) => {
        const questionLength = questions[index].length;
        answer.push(Array(questionLength).fill(null))
        setAnswers(answer);
      })

    }
  }, [questions]);

  const handleNext = () => {
    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResults(true);
    }
  }

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1)
    }
  }

  const saveAnswer = (answerValue: number, index: number) => {
    const aux = [...answers];
    if (!aux[currentQuestion]) {
      aux[currentQuestion] = [];
      aux[currentQuestion].push(answerValue);
    } else {
      aux[currentQuestion][index] = answerValue;
    }
    setAnswers(aux);
  }

  const calculate = async () => {
    let add = 0;

    answers.forEach((answer, index) => {
      const reducer = (accumulator: any, curr: any) => Number(accumulator) + Number(curr);
      const addition = answer.reduce(reducer);
      const response = addition && addition > maximumGradePerGroup[index] ? maximumGradePerGroup[index] : Number(addition);
      if (response) {
        add = add + response;
      }
    })

    if (add > 10) add = 10;
    setSum(add);

    const data = {
      id: user?.id,
      score: add,
    };

    await saveScore(data);

    handleNext();
  }

  const isButtonDisabled = () => answers && answers[currentQuestion]?.length !== questions[currentQuestion].length;

  const renderHeader = () => (
    <Row padded>
      {questions.map((q, index) =>
        <>
          {index > 0 && index < questions.length && <Spacing left={10} />}

          <Container
            borderColor={Theme.palette.secondary.main}
            style={{ borderRadius: 2 }}
            color={index <= currentQuestion ? Theme.palette.secondary.main : ''}
            width={window.outerWidth / questions.length}
            height={10}
          />
        </>
      )}
    </Row>
  )

  const redo = () => history.go(0);

  return (
    <Page>
      <Menu />

      {showResults ? (
        <Results sum={sum} redo={() => redo()} />
      ) : (
        <>
          {questions.length > 0 ? (
            <>
              {renderHeader()}

              {currentQuestion > 0 && (
                <Container>
                  <Button variant="text" onClick={() => handlePrevious()} style={{ textTransform: 'capitalize' }}>
                    <IoIosArrowRoundBack size={22} />
                    pergunta anterior
                  </Button>
                </Container>
              )}

              <Container fluid centered width="100%" height="100%">
                <Container fluid centered veryPadded={mobile}>
                  <Text color={Theme.palette.gray.main}>Questões {currentQuestion + 1}/{questions.length}</Text>

                  {questions[currentQuestion].map((question: any, index: number) => (
                    <>
                      <Spacing top={5}>
                        <Text size={mobile ? 18 : 28} center>{question.question}</Text>
                      </Spacing>

                      <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-helper-label">Selecione</InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          value={answers[currentQuestion] && answers[currentQuestion].length > 0 ? answers[currentQuestion][index] : ''}
                          onChange={(event) => saveAnswer(event.target.value as number, index)}
                          label="Selecione"
                          style={{ maxWidth: mobile ? (window.innerWidth - 40) : '100%' }}
                        >
                          <MenuItem value="" />
                          {question.answers?.map((option: any) =>
                            <MenuItem key={option.id} value={option.score} style={{ whiteSpace: 'normal', margin: '10px 0' }}>
                              <Text>{option.name}</Text>
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>

                      <Spacing bottom={30} />
                    </>
                  ))}

                  {(currentQuestion + 1) === questions.length ? (
                    <Button disabled={isButtonDisabled()} variant="contained" color="secondary" onClick={() => calculate()} style={{ textTransform: 'capitalize' }}>
                      Ver resultado
                    </Button>
                  ) : (
                    <Button disabled={isButtonDisabled()} variant="contained" color="secondary" onClick={() => handleNext()} style={{ textTransform: 'capitalize' }}>
                      Próxima questão
                    </Button>
                  )}
                </Container>
              </Container>
            </>
          ) : (
            <LoadingScreen />
          )}
        </>
      )}
    </Page >
  )
}

export default QuestionsPage;