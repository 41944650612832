import React, { createContext, useState, useContext, useEffect } from 'react';
import { IResizeContext } from './interfaces';

const ResizeContext = createContext<IResizeContext>({} as IResizeContext);

export const ResizeProvider: React.FC = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
    setMobile(width < 1000);
  }

  return (
    <ResizeContext.Provider value={{ windowWidth, mobile }}>
      {children}
    </ResizeContext.Provider>
  );
}

export const useResize = () => {
  const context = useContext(ResizeContext);

  if (!context) {
    throw new Error('useResize must be used from within an ResizeProvider');
  }

  return context;
}