import React from 'react';
import SpacingContainer from './styles';

interface ISpacing {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  horizontal?: number;
  vertical?: number;
  children?: any;
  width?: number | string;
}

const Spacing: React.FC<ISpacing> = ({
  top,
  bottom,
  left,
  right,
  horizontal,
  vertical,
  children,
  width
}) => {
  const topSize = top || vertical;
  const bottomSize = bottom || vertical;
  const leftSize = left || horizontal;
  const rightSize = right || horizontal;

  return (
    <SpacingContainer
      top={topSize}
      right={rightSize}
      bottom={bottomSize}
      left={leftSize}
      width={width}
    >
      {children}
    </SpacingContainer>
  );
};

export default Spacing;
