import styled from 'styled-components';

interface ISpacingContainer {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  width?: number | string;
}

const SpacingContainer = styled.div`
  margin-top: ${(props: ISpacingContainer) => `${props.top}px`};
  margin-bottom: ${(props: ISpacingContainer) => `${props.bottom}px`};
  margin-right: ${(props: ISpacingContainer) => `${props.right}px`};
  margin-left: ${(props: ISpacingContainer) => `${props.left}px`};

  width: ${(props: ISpacingContainer) => (typeof props.width === 'number' ? `${props.width}px` : props.width)};
`;

export default SpacingContainer;
