import styled from 'styled-components';

interface ITitleContainer {
  isBold: boolean;
  color?: string;
  center?: boolean;
}

const TitleContainer = styled.div`
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: ${(props: ITitleContainer) => (props.isBold ? 700 : 200)};
    color: ${(props: ITitleContainer) => `${props.color}`};
    text-align: ${(props: ITitleContainer) => props.center && 'center'};
  }
`;

export default TitleContainer;
