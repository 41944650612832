import axios, { AxiosRequestConfig } from 'axios';
const prodBaseUrl = 'https://api.psu.helpsmed.com.br';
const devBaseUrl = 'http://localhost:3333';

const userApi = axios.create({
  baseURL: prodBaseUrl,
});

userApi.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const token = localStorage.getItem('@HELPS:token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const admApi = axios.create({
  baseURL: prodBaseUrl,
});

admApi.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const admToken = localStorage.getItem('@HELPS:admToken');

  if (admToken) {
    config.headers.Authorization = `Bearer ${admToken}`;
  }

  return config;
});

export { userApi, admApi };
