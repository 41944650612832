import React, { useState } from 'react';
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  OutlinedInput,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@material-ui/core';
import InputMask from "react-input-mask";
import { FormControl as FC, InputLabel as IL, MenuItem, Select } from '@mui/material'
import { Container, HomeSideDiv, Row, Text, Title, Image, Spacing, Toast } from '../../../components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { createUser, signIn } from '../requests';
import { useResize } from '../../../hooks/resize';
import calcLogo from '../../../assets/calculadora_PSU-4.png';

interface IRegistrationForm {
  mobile: boolean;
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  privacyPolicy: boolean;
  setPrivacyPolicy: React.Dispatch<React.SetStateAction<boolean>>;
  save: (values: any) => void;
}

const userPeriod = [
  {
    label: '1º período',
    value: 1,
  },
  {
    label: '2º período',
    value: 2,
  },
  {
    label: '3º período',
    value: 3,
  },
  {
    label: '4º período',
    value: 4,
  },
  {
    label: '5º período',
    value: 5,
  },
  {
    label: '6º período',
    value: 6,
  },
  {
    label: '7º período',
    value: 7,
  },
  {
    label: '8º período',
    value: 8,
  },
  {
    label: '9º período',
    value: 9,
  },
  {
    label: '10º período',
    value: 10,
  },
  {
    label: '11º período',
    value: 11,
  },
  {
    label: '12º período',
    value: 12,
  },
  {
    label: 'Já sou formado(a)',
    value: 13,
  },
]

const RegistrationForm: React.FC<IRegistrationForm> = ({ mobile, checked, setChecked, privacyPolicy, setPrivacyPolicy, save }) => {
  const [showPassword, setShowPassword] = useState(false);

  const getValidationSchema = () =>
    yup.object().shape({
      name: yup.string().required('Informe seu nome'),
      phone: yup.string().required('Informe seu telefone'),
      college: yup.string().required('Informe o nome da faculdade em que você estuda'),
      period: yup.string().required('Informe o período que você está cursando'),
      email: yup.string().email('Informe um e-mail válido').required('Informe seu e-mail'),
      password: yup.string().required('Informe uma senha'),
    });

  return (
    <Formik
      initialValues={{
        name: '',
        phone: '',
        college: '',
        period: '',
        email: '',
        password: '',
        checked: true,
      }}
      validationSchema={getValidationSchema}
      enableReinitialize
      onSubmit={(values) => save(values)}
    >
      {({ values, handleSubmit, errors, touched, setFieldTouched, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <TextField
            required
            variant="outlined"
            label="Nome completo"
            value={values.name}
            style={{ width: '100%' }}
            inputProps={{ autoCapitalize: 'none' }}
            onBlur={() => setFieldTouched('name')}
            onChange={(value) => setFieldValue('name', value.target.value)}
            error={!!errors.name && !!touched.name}
            helperText={errors.name && touched.name ? errors.name : undefined}
          />

          <Spacing vertical={15}>
            <InputMask
              mask="(99) 99999-9999"
              value={values.phone}
              onChange={(value) => setFieldValue('phone', value.target.value)}
              onBlur={() => setFieldTouched('phone')}
            >
              {() => <TextField
                required
                variant="outlined"
                label="Telefone"
                style={{ width: '100%' }}
                error={!!errors.phone && !!touched.phone}
                helperText={errors.phone && touched.phone ? errors.phone : undefined}
              />}
            </InputMask>
          </Spacing>

          {!mobile ? (
            <Row>
              <TextField
                required
                variant="outlined"
                label="Faculdade"
                value={values.college}
                style={{ width: '100%' }}
                onBlur={() => setFieldTouched('college')}
                onChange={(value) => setFieldValue('college', value.target.value)}
                error={!!errors.college && !!touched.college}
                helperText={errors.college && touched.college ? errors.college : undefined}
              />

              <Spacing horizontal={5} />

              <FC variant="outlined" style={{ width: '100%' }}>
                <IL id="demo-simple-select-helper-label">Período</IL>
                <Select
                  labelId="demo-simple-select-helper-label"
                  value={values.period}
                  onChange={(value) => setFieldValue('period', value.target.value)}
                  label="Período"
                  onBlur={() => setFieldTouched('period')}
                  error={!!errors.period && !!touched.period}
                >
                  <MenuItem value="" />
                  {userPeriod.map((option: any) =>
                    <MenuItem key={option.label} value={option.value} style={{ whiteSpace: 'normal', margin: '10px 0' }}>
                      <Text>{option.label}</Text>
                    </MenuItem>
                  )}
                </Select>
                {errors.period && touched.period && (
                  <FormHelperText error id="accountId-error">
                    {errors.period}
                  </FormHelperText>
                )}
              </FC>
            </Row>
          ) : (
            <>
              <Spacing vertical={15}>
                <TextField
                  required
                  variant="outlined"
                  label="Faculdade"
                  value={values.college}
                  style={{ width: '100%' }}
                  onBlur={() => setFieldTouched('college')}
                  onChange={(value) => setFieldValue('college', value.target.value)}
                  error={!!errors.college && !!touched.college}
                  helperText={errors.college && touched.college ? errors.college : undefined}
                />
              </Spacing>

              <FC variant="outlined" style={{ width: '100%' }}>
                <IL id="demo-simple-select-helper-label">Período</IL>
                <Select
                  labelId="demo-simple-select-helper-label"
                  value={values.period}
                  onChange={(value) => setFieldValue('period', value.target.value)}
                  label="Período"
                  onBlur={() => setFieldTouched('period')}
                  error={!!errors.period && !!touched.period}
                >
                  <MenuItem value="" />
                  {userPeriod.map((option: any) =>
                    <MenuItem key={option.label} value={option.value} style={{ whiteSpace: 'normal', margin: '10px 0' }}>
                      <Text>{option.label}</Text>
                    </MenuItem>
                  )}
                </Select>
                {errors.period && touched.period && (
                  <FormHelperText error id="accountId-error">
                    {errors.period}
                  </FormHelperText>
                )}
              </FC>
            </>
          )}
          <Spacing vertical={15}>
            <TextField
              required
              variant="outlined"
              label="E-mail"
              value={values.email}
              style={{ width: '100%' }}
              onBlur={() => setFieldTouched('email')}
              onChange={(value) => setFieldValue('email', value.target.value)}
              error={!!errors.email && !!touched.email}
              helperText={errors.email && touched.email ? errors.email : undefined}
            />
          </Spacing>

          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel htmlFor="password">Senha *</InputLabel>

            <OutlinedInput
              id="password"
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>}
              labelWidth={70}
              onBlur={() => setFieldTouched('password')}
              onChange={(value) => setFieldValue('password', value.target.value)}
              error={!!errors.password && !!touched.password}
            />
            {errors.password && touched.password && (
              <FormHelperText error id="accountId-error">
                {errors.password}
              </FormHelperText>
            )}
          </FormControl>

          <Spacing top={15}>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} />}
              label="Desejo receber comunicações da HELPS e arrasar no currículo"
            />
          </Spacing>

          <Row verticallyCentered>
            <Checkbox checked={privacyPolicy} onChange={() => setPrivacyPolicy(!privacyPolicy)} style={{ marginLeft: -11 }} />
            <Link to={'/politica-de-privacidade'}>
              <Text>Concordo com os Termos de uso e Política de privacidade</Text>
            </Link>
          </Row>

          <Spacing bottom={30} />

          <Container fluid style={{ alignItems: 'flex-end' }}>
            <Button variant="contained" disabled={!privacyPolicy} color="secondary" type="submit" style={{ textTransform: 'capitalize' }}>
              Criar conta
            </Button>
          </Container>

          <Spacing top={20} bottom={50}>
            <Text center>Já possui conta?{' '}
              <Link to={'/'}>
                Entrar
              </Link>
            </Text>
          </Spacing>
        </Form>
      )}
    </Formik>
  )
}

const RegistrationPage: React.FC = () => {
  const history = useHistory();
  const { mobile } = useResize();

  const [checked, setChecked] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);


  const save = async (values: any) => {
    if (!privacyPolicy) {
      Toast('Você deve concordar com os Termos de uso e a Política de privacidade da HELPS!', 'bottom-right', 'warning');
      return;
    }

    const {
      name,
      phone,
      college,
      period,
      email,
      password
    } = values || {};

    const data = {
      name,
      phone,
      college,
      period,
      email: email.toLowerCase(),
      password,
      allowCommunication: checked
    };

    const response = await createUser(data);

    if (response) {
      Toast('Bem-vindo(a) à Calculadora HELPS!', 'bottom-right', 'success');
      const response = await signIn({ email: email.toLowerCase(), password });
      if (response) {
        history.push('/');
        history.go(0);
      }
    }
  }

  return (
    <>
      {!mobile ? (
        <Row>
          <HomeSideDiv />

          <Container width="75%" fluid centered style={{ overflowY: 'auto' }}>
            <Container height="100vh" veryPadded width={550} >
              <Spacing vertical={30}>
                <Title size="regular">Criar uma conta na Calculadora PSU - HELPS Med</Title>
              </Spacing>

              <RegistrationForm
                mobile={mobile}
                checked={checked}
                setChecked={setChecked}
                privacyPolicy={privacyPolicy}
                setPrivacyPolicy={setPrivacyPolicy}
                save={save}
              />
            </Container>
          </Container>
        </Row>
      ) : (
        <Container height="100vh" veryPadded >
          <Container fluid centered>
            <Image source={calcLogo} size={250} />
          </Container>

          <Spacing bottom={30}>
            <Title size="regular">Criar uma conta na Calculadora PSU - HELPS Med</Title>
          </Spacing>

          <RegistrationForm
            mobile={mobile}
            checked={checked}
            setChecked={setChecked}
            privacyPolicy={privacyPolicy}
            setPrivacyPolicy={setPrivacyPolicy}
            save={save}
          />
        </Container>
      )}
    </>
  )
}

export default RegistrationPage;