import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Link as TextLink, TextField, FormControl, InputLabel, FormHelperText, Select } from '@material-ui/core';
import { Container, PassingScoreTable, Modal, Spacing, SideMenu, Text, Theme, Toast, LoadingScreen } from '../../../components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { getColleges, getAdminPassingScores, getSpecialities, savePassingScore, saveCollege, saveSpeciality } from './requests';

interface ISelector {
  label: string;
  value: string;
}

const AdminMinimunScorePage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newCollegeOpen, setNewCollegeOpen] = useState(false);
  const [newSpecialityOpen, setNewSpecialityOpen] = useState(false);
  const [passingScores, setPassingScores] = useState<any[]>([]);
  const [colleges, setColleges] = useState<ISelector[]>([]);
  const [specialities, setSpecialities] = useState<ISelector[]>([]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getAllPassingScores();
      await getAllSpecialities();
      await getAllColleges();
      setLoading(false);
    }

    getData();
  }, []);

  const getAllPassingScores = async () => {
    const passingScoresRequest = await getAdminPassingScores();

    if (passingScoresRequest.length) {
      const mapped = passingScoresRequest.map((university: any) => {
        const formatted = university.speciality.map((speciality: any) => ({
          university: university.name,
          speciality: speciality.name,
          score: speciality.college_speciality?.passing_score,
        }));

        return formatted;
      });

      setPassingScores(mapped.flat());
    }
  }

  const getAllSpecialities = async () => {
    const specialitiesRequest = await getSpecialities();

    if (specialitiesRequest.length) {
      const mapped = specialitiesRequest.map((specialities: any) => ({ label: specialities.name, value: specialities.id }));
      setSpecialities(mapped);
    }
  }

  const getAllColleges = async () => {
    const collegesRequest = await getColleges();

    if (collegesRequest.length) {
      const mapped = collegesRequest.map((college: any) => ({ label: college.name, value: college.id }));
      setColleges(mapped);
    }
  }

  const handleSaveCollege = async (values: any) => {
    const response = await saveCollege({ name: values.college });

    if (response) {
      setNewCollegeOpen(false);
      getAllColleges();
    }
  }

  const handleSaveSpeciality = async (values: any) => {
    const response = await saveSpeciality({ name: values.speciality });

    if (response) {
      setNewSpecialityOpen(false);
      getAllSpecialities();
    }

  }

  const handleSubmit = async (values: any) => {
    const { college, speciality, passingScore } = values || {};

    const selectedSpeciality = specialities.find((item: any) => item.value === speciality);

    if (selectedSpeciality) {
      const data = {
        college,
        passingScore,
        speciality: selectedSpeciality?.label,
      };

      const response = await savePassingScore(data);

      if (response) {
        Toast('Nota de corte cadastrada com sucesso', 'bottom-right', 'info');
        setOpen(false);
        getAllPassingScores()
      }
    }
  }

  const handleCloseNewGrade = () => {
    setOpen(false);
  };

  return (
    <>
      <SideMenu title="Notas de corte">
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            <Spacing bottom={20}>
              <Container fluid style={{ alignItems: 'flex-end' }}>
                <Button variant="contained" color="secondary" onClick={() => setOpen(true)} style={{ textTransform: 'capitalize' }}>
                  Nova nota +
                </Button>
              </Container>
            </Spacing>

            <PassingScoreTable rows={passingScores} />
          </>
        )}
      </SideMenu>

      <Modal isOpen={open} onClose={handleCloseNewGrade}>
        <Container color={Theme.palette.white.main} rounded veryPadded width={450}>
          <Text>Nota de corte</Text>

          <Formik
            initialValues={{
              college: '',
              speciality: '',
              passingScore: '',
            }}
            validationSchema={
              yup.object().shape({
                college: yup.string().required('Informe a universidade'),
                speciality: yup.string().required('Informe a especialidade'),
                passingScore: yup.number().required('Informe a nota de corte'),
              })
            }
            enableReinitialize
            onSubmit={(values: any) => handleSubmit(values)}
          >
            {({ values, handleSubmit, errors, touched, setFieldTouched, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Spacing vertical={20}>
                  <>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <InputLabel htmlFor="outlined-age-native-simple">Universidade</InputLabel>
                      <Select
                        native
                        required
                        value={values.college}
                        onChange={(value: any) => setFieldValue('college', value.target.value)}
                        inputProps={{
                          name: "age",
                          id: "outlined-age-native-simple"
                        }}
                        error={!!errors.college && !!touched.college}
                      >
                        <option aria-label="None" value="" />
                        {colleges?.map((option) =>
                          <option key={option.value} value={option.value}>{option.label}</option>)
                        }
                      </Select>
                      {errors.college && touched.college && (
                        <FormHelperText error id="accountId-error">
                          {errors.college}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <Container fluid style={{ alignItems: 'flex-end' }}>
                      <TextLink onClick={() => setNewCollegeOpen(true)}>
                        Nova instituição +
                      </TextLink>
                    </Container>
                  </>

                  <Spacing vertical={15}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <InputLabel htmlFor="outlined-age-native-simple">Especialidade</InputLabel>
                      <Select
                        native
                        required
                        value={values.speciality}
                        onChange={(value: any) => setFieldValue('speciality', value.target.value)}
                        inputProps={{
                          name: "age",
                          id: "outlined-age-native-simple"
                        }}
                        error={!!errors.speciality && !!touched.speciality}
                      >
                        <option aria-label="None" value="" />
                        {specialities?.map((option) =>
                          <option key={option.value} value={option.value}>{option.label}</option>)
                        }
                      </Select>
                      {errors.speciality && touched.speciality && (
                        <FormHelperText error id="accountId-error">
                          {errors.speciality}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <Container fluid style={{ alignItems: 'flex-end' }}>
                      <TextLink onClick={() => setNewSpecialityOpen(true)}>
                        Nova especialidade +
                      </TextLink>
                    </Container>
                  </Spacing>

                  <TextField
                    required
                    variant="outlined"
                    label="Nota de corte"
                    value={values.passingScore}
                    style={{ width: '100%' }}
                    onBlur={() => setFieldTouched('passingScore')}
                    onChange={(value) => setFieldValue('passingScore', value.target.value)}
                    error={!!errors.passingScore && !!touched.passingScore}
                    helperText={errors.passingScore && touched.passingScore ? errors.passingScore : undefined}
                  />
                </Spacing>

                <Container fluid style={{ alignItems: 'flex-end' }}>
                  <Button variant="contained" color="secondary" type="submit" style={{ textTransform: 'capitalize' }}>
                    Salvar
                  </Button>
                </Container>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal>

      <Modal isOpen={newCollegeOpen} onClose={() => setNewCollegeOpen(false)}>
        <Container color={Theme.palette.white.main} rounded veryPadded width={450}>
          <Text>Nova universidade</Text>

          <Formik
            initialValues={{
              college: '',
            }}
            enableReinitialize
            validationSchema={
              yup.object().shape({
                college: yup.string().required('Informe a universidade'),
              })
            }
            onSubmit={(values) => handleSaveCollege(values)}
          >
            {({ values, handleSubmit, errors, touched, setFieldTouched, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Spacing vertical={20}>
                  <TextField
                    required
                    variant="outlined"
                    label="Universidade"
                    value={values.college}
                    style={{ width: '100%' }}
                    onBlur={() => setFieldTouched('college')}
                    onChange={(value) => setFieldValue('college', value.target.value)}
                    error={!!errors.college && !!touched.college}
                    helperText={errors.college && touched.college ? errors.college : undefined}
                  />
                </Spacing>

                <Container fluid style={{ alignItems: 'flex-end' }}>
                  <Button variant="contained" color="secondary" type="submit" style={{ textTransform: 'capitalize' }}>
                    Salvar
                  </Button>
                </Container>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal>

      <Modal isOpen={newSpecialityOpen} onClose={() => setNewSpecialityOpen(false)}>
        <Container color={Theme.palette.white.main} rounded veryPadded width={450}>
          <Text>Nova especialidade</Text>

          <Formik
            initialValues={{
              speciality: '',
            }}
            enableReinitialize
            validationSchema={
              yup.object().shape({
                speciality: yup.string().required('Informe a especialidade'),
              })
            }
            onSubmit={(values) => handleSaveSpeciality(values)}
          >
            {({ values, handleSubmit, errors, touched, setFieldTouched, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Spacing vertical={20}>
                  <TextField
                    required
                    variant="outlined"
                    label="Especialidade"
                    value={values.speciality}
                    style={{ width: '100%' }}
                    onBlur={() => setFieldTouched('speciality')}
                    onChange={(value) => setFieldValue('speciality', value.target.value)}
                    error={!!errors.speciality && !!touched.speciality}
                    helperText={errors.speciality && touched.speciality ? errors.speciality : undefined}
                  />
                </Spacing>

                <Container fluid style={{ alignItems: 'flex-end' }}>
                  <Button variant="contained" color="secondary" type="submit" style={{ textTransform: 'capitalize' }}>
                    Salvar
                  </Button>
                </Container>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal>

    </>
  )
}

export default AdminMinimunScorePage;

