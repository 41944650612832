import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { AskForHelps, Container, Image, Page, Menu, Text, Row, Spacing, ScoresTable } from '../../components';
import { getScores } from './requests';
import { useAuth } from '../../hooks/auth';
import { useResize } from '../../hooks/resize';
import screen from '../../assets/screen.svg';

const MyGrades: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { mobile } = useResize();

  const [scores, setScores] = useState<any[]>([]);

  useEffect(() => {
    const getData = async () => {
      if (user) {
        const response = await getScores(user.id);

        if (response) {
          const mapped = response.map((score: any) => ({
            date: score.createdAt,
            score: score.score,
          }))

          setScores(mapped);
        }
      }
    }

    getData();
  }, [user]);

  return (
    <Page>
      <Menu />

      {scores.length ?
        <Container padded style={{ padding: mobile ? '25px 20px' : '25px 60px' }}>
          <Spacing bottom={30}>
            <Row verticallyCentered spacedBetween>
              <Text bold size={mobile ? 16 : 20}>Minhas notas</Text>
              <Button variant="contained" color="secondary" onClick={() => history.push('/questionario')} style={{ textTransform: 'capitalize' }}>
                Novo teste +
              </Button>
            </Row>
          </Spacing>

          {scores && <ScoresTable scores={scores} />}
        </Container>
        :
        <Container fluid centered width="100%" height="100%">
          <Image source={screen} size={mobile ? 150 : 250} />

          <Spacing vertical={15}>
            <Text center>Parece que você ainda não descobriu sua nota no PSU!</Text>
          </Spacing>
          <Link to="/questionario">
            <Text bold center>Responder questionário e descobrir nota</Text>
          </Link>
        </Container>
      }
      <AskForHelps />
    </Page>
  )
}

export default MyGrades;
