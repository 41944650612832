/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Button, Link as TextLink, TextField, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Container, Modal, SideMenu, Spacing, Text, Row, Theme, LoadingScreen } from '../../../components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { MdExpandMore } from 'react-icons/md';
import { saveQuestion, saveAnswers, editAnswers, editQuestion as editSavedQuestion, getAllQuestions } from './requests';
import { FiEdit3 } from 'react-icons/fi';

interface IAddOrEditQuestionModal {
  newQuestionOpen: boolean;
  newQuestion: null[];
  answer: string[];
  score: string[];
  questionToEdit: any;
  handleChangeAnswer: (value: string, index: number) => void;
  handleChangeScore: (value: string, index: number) => void;
  handleSaveQuestion: (values: any) => void;
  addNewQuestionRow: () => void;
  handleCloseModal: () => void;
  setNewQuestion: Dispatch<SetStateAction<null[]>>;
  setAnswer: Dispatch<SetStateAction<string[]>>;
  setScore: Dispatch<SetStateAction<string[]>>;
}

const AddOrEditQuestionModal: React.FC<IAddOrEditQuestionModal> = ({
  newQuestionOpen,
  newQuestion,
  answer,
  score,
  questionToEdit,
  handleSaveQuestion,
  handleChangeAnswer,
  handleChangeScore,
  addNewQuestionRow,
  handleCloseModal,
  setNewQuestion,
  setAnswer,
  setScore,
}) => {
  useEffect(() => {
    if (questionToEdit && questionToEdit.answers) {
      const arraySize = !questionToEdit.answers?.length ? questionToEdit.answers?.length + 1 : questionToEdit.answers?.length;
      const auxNewQuestion = Array(arraySize).fill(null);
      const auxAnswer = questionToEdit.answers.map((answer: any) => answer.name);
      const auxScore = questionToEdit.answers.map((answer: any) => answer.score);
      setScore(auxScore);
      setAnswer(auxAnswer);
      setNewQuestion(auxNewQuestion);
    }
  }, [questionToEdit]);

  return (
    <Modal isOpen={newQuestionOpen} onClose={() => handleCloseModal()}>
      <Container color={Theme.palette.white.main} rounded veryPadded width={450}>
        <Text>Nova pergunta</Text>

        <Formik
          initialValues={{
            question: questionToEdit ? questionToEdit.question : '',
            group: questionToEdit ? questionToEdit.group : 0
          }}
          enableReinitialize
          validationSchema={
            yup.object().shape({
              question: yup.string().required('Informe a pergunta'),
            })
          }
          onSubmit={(values) => handleSaveQuestion(values)}
        >
          {({ values, handleSubmit, errors, touched, setFieldTouched, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Container style={{ overflowY: 'auto', maxHeight: 350, marginBottom: 10 }}>
                <Spacing bottom={30} top={5}>
                  <TextField
                    required
                    variant="outlined"
                    label="Digite a pergunta"
                    value={values.question}
                    style={{ width: '100%' }}
                    onBlur={() => setFieldTouched('question')}
                    onChange={(value) => setFieldValue('question', value.target.value)}
                    error={!!errors.question && !!touched.question}
                    helperText={errors.question && touched.question ? errors.question : undefined}
                  />
                  <Spacing bottom={10} />
                  <TextField
                    required
                    variant="outlined"
                    label="Grupo"
                    value={values.group}
                    style={{ width: '100%' }}
                    onBlur={() => setFieldTouched('group')}
                    onChange={(value) => setFieldValue('group', value.target.value)}
                    error={!!errors.group && !!touched.group}
                    helperText={errors.group && touched.group ? errors.group : undefined}
                  />
                </Spacing>


                {
                  newQuestion.length >= 0 && newQuestion.map((question, index) => (
                    <Spacing bottom={30}>
                      <Spacing bottom={5}>
                        <Text bold>Resposta {index + 1}</Text>
                      </Spacing>

                      <TextField
                        required
                        variant="outlined"
                        label="Digite a resposta"
                        value={answer[index]}
                        style={{ width: '100%' }}
                        onChange={(value) => handleChangeAnswer(value.target.value, index)}
                      />

                      <Spacing vertical={10} />

                      <TextField
                        required
                        variant="outlined"
                        label="Pontuação da resposta"
                        value={score[index]}
                        style={{ width: '100%' }}
                        onChange={(value) => handleChangeScore(value.target.value, index)}
                      />
                    </Spacing>
                  ))
                }

                <Container fluid style={{ alignItems: 'flex-end' }}>
                  <TextLink underline="always" onClick={() => addNewQuestionRow()} style={{ cursor: 'pointer' }}>
                    Nova resposta +
                  </TextLink>
                </Container>
              </Container>

              <Container fluid style={{ alignItems: 'flex-end' }}>
                <Button variant="contained" color="secondary" type="submit" style={{ textTransform: 'capitalize' }}>
                  Salvar
                </Button>
              </Container>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal >
  )
}

const QuestionsAndAnswersPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [allQuestions, setAllQuestions] = useState([]);
  const [newQuestionOpen, setNewQuestionOpen] = useState(false);
  const [newQuestion, setNewQuestion] = useState<null[]>([null]);
  const [answer, setAnswer] = useState<string[]>([]);
  const [score, setScore] = useState<string[]>([]);
  const [questionToEdit, setQuestionToEdit] = useState<any>(null);

  useEffect(() => {
    getQuestions();
  }, []);

  const getQuestions = async () => {
    setLoading(true);

    const response = await getAllQuestions();

    if (response) {
      setAllQuestions(response);
    }

    setLoading(false);
  }

  const handleSaveQuestion = async (values: any) => {
    if (questionToEdit) {
      const questionId = await editSavedQuestion(questionToEdit.id, { question: values.question, group: values.group });

      if (questionId) {
        const data = {
          answers: answer,
          scores: score,
        };

        const response = await editAnswers(questionId, data);

        if (response) {
          handleCloseModal();
          getQuestions();
        }
      }
    } else {
      const questionId = await saveQuestion(values);

      if (questionId) {
        const data = {
          answers: answer,
          scores: score,
        };

        const response = await saveAnswers(questionId, data);

        if (response) {
          handleCloseModal();
          getQuestions();
        }
      }
    }
  }

  const handleCloseModal = () => {
    setNewQuestionOpen(false);
    setQuestionToEdit(null);
    setNewQuestion([null]);
    setAnswer([]);
    setScore([]);
  }

  const handleChangeAnswer = (value: string, index: number) => {
    const auxAnswer: string[] = [...answer];
    auxAnswer[index] = value;
    setAnswer(auxAnswer);
  }

  const handleChangeScore = (value: string, index: number) => {
    const auxScore: string[] = [...score];
    auxScore[index] = value;
    setScore(auxScore);
  }

  const addNewQuestionRow = () => {
    if (answer.length && answer[newQuestion.length - 1] !== '' && score.length && score[newQuestion.length - 1] !== '') {
      setNewQuestion([...newQuestion, null])
    }
  }

  const editQuestion = (question: any) => {
    setQuestionToEdit(question);
    setNewQuestionOpen(true);
  }

  return (
    <>
      <SideMenu title="Perguntas e respostas">
        {
          loading ? <LoadingScreen /> : (
            <>
              {/* <Spacing bottom={20}>
                <Container fluid style={{ alignItems: 'flex-end' }}>
                  <Button variant="contained" color="secondary" onClick={() => setNewQuestionOpen(true)} style={{ textTransform: 'capitalize' }}>
                    Cadastrar pergunta
                  </Button>
                </Container>
              </Spacing> */}

              {allQuestions.length && allQuestions?.map((question: any) => (
                <Spacing bottom={10}>
                  <Accordion style={{ borderRadius: 5 }}>
                    <AccordionSummary
                      expandIcon={<MdExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Row fluid spacedBetween>
                        <Text>{question.question}</Text>
                        <Button onClick={() => editQuestion(question)}>
                          <FiEdit3 />
                        </Button>
                      </Row>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ flexDirection: 'column' }}>
                      {
                        question.answers?.map((answer: any) => (
                          <Spacing bottom={10}>
                            <Row fluid spacedBetween>
                              <Text color={Theme.palette.gray.main}>{answer.name}</Text>
                              <Text color={Theme.palette.gray.main}>{parseFloat(answer.score).toFixed(2)}</Text>
                            </Row>
                          </Spacing>
                        ))
                      }
                    </AccordionDetails>
                  </Accordion>
                </Spacing>
              ))}
            </>
          )
        }
      </SideMenu>

      <AddOrEditQuestionModal
        score={score}
        answer={answer}
        newQuestion={newQuestion}
        newQuestionOpen={newQuestionOpen}
        questionToEdit={questionToEdit}
        setScore={setScore}
        setAnswer={setAnswer}
        setNewQuestion={setNewQuestion}
        handleCloseModal={handleCloseModal}
        handleChangeScore={handleChangeScore}
        handleSaveQuestion={handleSaveQuestion}
        handleChangeAnswer={handleChangeAnswer}
        addNewQuestionRow={addNewQuestionRow}
      />
    </>
  )
}

export default QuestionsAndAnswersPage;