import React, { useState } from 'react';
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, FormHelperText, TextField } from '@material-ui/core';
import { Container, Row, Text, Menu, Page, Spacing, Toast } from '../../../components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { useResize } from '../../../hooks/resize';
import { updateUserPassword as updatePassword } from '../requests';

interface ILoginDataForm {
  showCurrentPassword: boolean;
  setShowCurrentPassword: React.Dispatch<React.SetStateAction<boolean>>;
  showNewPassword: boolean;
  setShowNewPassword: React.Dispatch<React.SetStateAction<boolean>>;
  showConfirmNewPassword: boolean;
  setShowConfirmNewPassword: React.Dispatch<React.SetStateAction<boolean>>;
  updateUserPassword: (values: any) => void;
}

const LoginDataForm: React.FC<ILoginDataForm> = ({
  showCurrentPassword,
  setShowCurrentPassword,
  showNewPassword,
  setShowNewPassword,
  showConfirmNewPassword,
  setShowConfirmNewPassword,
  updateUserPassword
}) => {
  const { user } = useAuth();

  return (
    <Formik
      initialValues={{
        email: user ? user.email : '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      }}
      enableReinitialize
      validationSchema={
        yup.object().shape({
          currentPassword: yup.string().required('Informe sua senha atual'),
          confirmNewPassword: yup.string().required('Informe sua nova senha'),
          newPassword: yup.string().required('Confirme sua nova senha'),
        })
      }
      onSubmit={(values) => updateUserPassword(values)}
    >
      {({ values, handleSubmit, errors, touched, setFieldTouched, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Spacing bottom={15}>
            <TextField variant="outlined" label="E-mail" value={values.email} disabled style={{ width: '100%' }} />
          </Spacing>

          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel htmlFor="password">Senha atual</InputLabel>

            <OutlinedInput
              id="currentPassword"
              type={showCurrentPassword ? 'text' : 'password'}
              value={values.currentPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle currentPassword visibility"
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    edge="end"
                  >
                    {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>}
              labelWidth={70}
              onBlur={() => setFieldTouched('currentPassword')}
              onChange={(value) => setFieldValue('currentPassword', value.target.value)}
              error={!!errors.currentPassword && !!touched.currentPassword}
            />
            {errors.currentPassword && touched.currentPassword && (
              <FormHelperText error id="accountId-error">
                {errors.currentPassword}
              </FormHelperText>
            )}
          </FormControl>

          <Spacing vertical={10} />

          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel htmlFor="password">Nova senha</InputLabel>

            <OutlinedInput
              id="newPassword"
              type={showNewPassword ? 'text' : 'password'}
              value={values.newPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle newPassword visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    edge="end"
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>}
              labelWidth={70}
              onBlur={() => setFieldTouched('newPassword')}
              onChange={(value) => setFieldValue('newPassword', value.target.value)}
              error={!!errors.newPassword && !!touched.newPassword}
            />
            {errors.newPassword && touched.newPassword && (
              <FormHelperText error id="accountId-error">
                {errors.newPassword}
              </FormHelperText>
            )}
          </FormControl>

          <Spacing vertical={10} />

          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel htmlFor="password">Confirme sua nova senha</InputLabel>

            <OutlinedInput
              id="confirmNewPassword"
              type={showConfirmNewPassword ? 'text' : 'password'}
              value={values.confirmNewPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirmNewPassword visibility"
                    onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                    edge="end"
                  >
                    {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>}
              labelWidth={70}
              onBlur={() => setFieldTouched('confirmNewPassword')}
              onChange={(value) => setFieldValue('confirmNewPassword', value.target.value)}
              error={!!errors.confirmNewPassword && !!touched.confirmNewPassword}
            />
            {errors.confirmNewPassword && touched.confirmNewPassword && (
              <FormHelperText error id="accountId-error">
                {errors.confirmNewPassword}
              </FormHelperText>
            )}
          </FormControl>

          <Spacing bottom={30} />

          <Container fluid style={{ alignItems: 'flex-end' }}>
            <Button variant="contained" color="secondary" type="submit" style={{ textTransform: 'capitalize' }}>
              Salvar dados de login
            </Button>
          </Container>
        </Form>
      )}
    </Formik>
  )
}

const LoginDataPage: React.FC = () => {
  const { user } = useAuth();
  const { mobile } = useResize();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const updateUserPassword = async (values: any) => {
    const { currentPassword, newPassword, confirmNewPassword } = values || {};
    if (values.confirmNewPassword !== values.newPassword) {
      Toast('A nova senha e sua confirmação não são idênticas. Por favor, verifique e tente novamente!', 'bottom-right', 'warning');
      return;
    }

    const response = await updatePassword(user?.id, { currentPassword, newPassword, confirmNewPassword });

    if (response) {
      Toast('Senha atualizada com sucesso!', 'bottom-right', 'success');
    }
  };

  return (
    <Page>
      <Menu />
      {!mobile ? (
        <Row>
          <Container width="40%" fluid style={{ alignItems: 'flex-end' }}>
            <Spacing top={100}>
              <Container>
                <Text bold>Editar Perfil</Text>

                <Spacing top={30} bottom={10}>
                  <Link to="/dados-pessoais" style={{ textDecoration: 'none' }}>
                    <Text>Dados pessoais</Text>
                  </Link>
                </Spacing>

                <Link to="/dados-pessoais/login" style={{ textDecoration: 'none' }}>
                  <Text bold>Dados de login</Text>
                </Link>
              </Container>
            </Spacing>
          </Container>

          <Spacing horizontal={50} />

          <Container width="60%" height="100vh" >
            <Spacing top={140} />

            <Container fluid veryPadded width={400} height="100%">
              <LoginDataForm
                showCurrentPassword={showCurrentPassword}
                setShowCurrentPassword={setShowCurrentPassword}
                showNewPassword={showNewPassword}
                setShowNewPassword={setShowNewPassword}
                showConfirmNewPassword={showConfirmNewPassword}
                setShowConfirmNewPassword={setShowConfirmNewPassword}
                updateUserPassword={updateUserPassword}
              />
            </Container>
          </Container>
        </Row>
      ) : (
        <Container veryPadded style={{ overflowY: 'auto' }}>
          <Text bold>Editar Perfil</Text>

          <Spacing top={30} bottom={10}>
            <Link to="/dados-pessoais">
              <Text>Dados pessoais</Text>
            </Link>
          </Spacing>

          <Link to="/dados-pessoais/login">
            <Text bold>Dados de login</Text>
          </Link>

          <Spacing top={30}>
            <LoginDataForm
              showCurrentPassword={showCurrentPassword}
              setShowCurrentPassword={setShowCurrentPassword}
              showNewPassword={showNewPassword}
              setShowNewPassword={setShowNewPassword}
              showConfirmNewPassword={showConfirmNewPassword}
              setShowConfirmNewPassword={setShowConfirmNewPassword}
              updateUserPassword={updateUserPassword}
            />
          </Spacing>
        </Container>
      )}
    </Page>
  )
}

export default LoginDataPage;