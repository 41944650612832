import React from 'react';
import { Route, } from 'react-router-dom';
import LoginPage from '../pages/Auth/Login';
import ForgotPasswordPage from '../pages/Auth/ForgotPassword';
import RegistrationPage from '../pages/Auth/Registration';

import PrivacyPolicyPage from '../pages/PrivacyPolicy';

const PublicRoute: React.FC = () => {
  return (
    <>
      <Route path="/" exact component={LoginPage} />
      <Route path="/esqueci-minha-senha" exact component={ForgotPasswordPage} />
      <Route path="/cadastrar" exact component={RegistrationPage} />
      <Route path="/politica-de-privacidade" exact component={PrivacyPolicyPage} />
    </>
  )
}

export default PublicRoute;
