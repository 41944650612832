import React from 'react';
import Container from '../../Atoms/Container';
import Title from '../../Atoms/Title';
import Text from '../../Atoms/Text';
import Spacing from '../../Atoms/Spacing';
import Logo from '../Logo';
import SocialMedia from '../SocialMedia';
import Theme from '../../Theme';

const HomeSideDiv: React.FC = () => {
  return (
    <Container width="25%" height="100vh" color={Theme.palette.primary.main} style={{ padding: 60 }}>
      <Logo />

      <Title color={Theme.palette.white.main}>Calculadora PSU - HELPS Med</Title>

      <Spacing top={15}>
        <Text color={Theme.palette.white.main}>Calcule, de forma fácil e rápida, a sua nota PSU</Text>
      </Spacing>

      <SocialMedia />
    </Container>
  )
}

export default HomeSideDiv;